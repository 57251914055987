import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Heading,
  VStack,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Flex,
  Spinner,
  Button,
  Center,
  Select
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useNavigate, useParams, useSearchParams, Outlet } from 'react-router-dom';
import { db } from '../../Firebase';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import { useAuth } from '../AuthContext';
import { FiFolder } from 'react-icons/fi';

const CallLogs = () => {
  const { workspaceId, callId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [callTypeFilter, setCallTypeFilter] = useState('all');
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const formatPhoneNumber = useCallback((phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    if (cleaned.length === 10) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
    } else if (cleaned.length === 11) {
      return `+${cleaned.slice(0, 1)} (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7)}`;
    }
    return phoneNumber; // Return original if not 10 or 11 digits
  }, []);

  const getOrdinalSuffix = useCallback((day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  }, []);

  const formatDate = useCallback((dateString) => {
    const date = new Date(dateString);
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June', 
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    return `${month} ${day}${getOrdinalSuffix(day)}, ${year} ${formattedHours}:${formattedMinutes} ${ampm}`;
  }, [getOrdinalSuffix]);

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
      return;
    }

    const logsCollectionRef = collection(db, `workspaces/${workspaceId}/logs`);
    const q = query(logsCollectionRef, orderBy('message.call.createdAt', 'desc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const logsList = snapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          rawNumber: data.message.call.customer.number.replace(/\D/g, ''),
          formattedNumber: formatPhoneNumber(data.message.call.customer.number),
          formattedDate: formatDate(data.message.call.createdAt),
          callType: data.message.call.type
        };
      });

      setLogs(logsList);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [workspaceId, currentUser, navigate, formatPhoneNumber, formatDate]);

  useEffect(() => {
    const searchTerm = searchParams.get("search") || "";
    const callType = searchParams.get("callType") || "all";
    setSearchQuery(searchTerm);
    setCallTypeFilter(callType);

    let filtered = logs;

    if (searchTerm) {
      filtered = filtered.filter((log) =>
        log.rawNumber.includes(searchTerm.replace(/\D/g, '')) ||
        log.formattedDate.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (callType !== 'all') {
      filtered = filtered.filter((log) => log.callType === callType);
    }

    setFilteredLogs(filtered);
  }, [logs, searchParams]);

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    setSearchQuery(searchTerm);
    updateSearchParams(searchTerm, callTypeFilter);
  };

  const handleCallTypeChange = (e) => {
    const callType = e.target.value;
    setCallTypeFilter(callType);
    updateSearchParams(searchQuery, callType);
  };

  const updateSearchParams = (search, callType) => {
    const params = {};
    if (search) params.search = search;
    if (callType !== 'all') params.callType = callType;
    setSearchParams(params);
  };

  const handleCallClick = (log) => {
    navigate(`/dashboard/${workspaceId}/call-logs/${log.id}`);
  };

  return (
    <Box display="flex" height="100%">
      <VStack align="stretch" width="400px" height="100%" p={4} borderRight="1px" borderRightColor="gray.200">
        <Flex align="center">
          <FiFolder size={30} style={{ marginRight: '12px' }} />
          <Heading as="h2" size="xl">
            Call Logs
          </Heading>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" mb={2}>
          <Text fontSize="xl" fontWeight="bold">
            {filteredLogs.length} Calls
          </Text>
          <Select
            width="150px"
            value={callTypeFilter}
            onChange={handleCallTypeChange}
          >
            <option value="all">All Calls</option>
            <option value="inboundPhoneCall">Inbound</option>
            <option value="outboundPhoneCall">Outbound</option>
          </Select>
        </Flex>
        <InputGroup size="lg">
          <InputLeftElement pointerEvents="none">
            <Icon as={SearchIcon} color="gray.400" />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search by phone or date"
            bg="white"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </InputGroup>
        <Box
          height="calc(100vh - 266px)"
          overflowY="scroll"
          css={{
            '&::-webkit-scrollbar': {
              width: '0px',
            },
            '&::-webkit-scrollbar-track': {
              width: '0px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'transparent',
            },
          }}
        >
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <Spinner />
            </Box>
          ) : (
            <VStack spacing={2}>
              {filteredLogs.map((log) => (
                <Button
                  key={log.id}
                  width="100%"
                  onClick={() => handleCallClick(log)}
                  bg={callId === log.id ? 'gray.200' : 'white'}
                  _hover={{ bg: callId === log.id ? 'gray.300' : 'gray.200' }}
                  borderWidth="1px"
                  borderColor="gray.200"
                >
                  <Flex width="100%" justifyContent="space-between" alignItems="center">
                    <Text>{log.formattedNumber}</Text>
                    <Text fontSize="sm">{log.formattedDate}</Text>
                  </Flex>
                </Button>
              ))}
            </VStack>
          )}
        </Box>
      </VStack>
      <Box flex="1" p={4}>
        {callId ? (
          <Outlet />
        ) : (
          <Center height="100%">
            <Text fontSize="2xl" fontWeight="bold" color="gray.500">
              Please Select a Call
            </Text>
          </Center>
        )}
      </Box>
    </Box>
  );
};

export default CallLogs;