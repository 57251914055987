import React, { useState, useEffect, useCallback } from 'react';
import { Container, Heading, Input, Button, Text, VStack, Link } from '@chakra-ui/react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, updateDoc, arrayUnion, collection, addDoc, serverTimestamp } from 'firebase/firestore';

const HandleInvite = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isNewUser, setIsNewUser] = useState(true);
  const [workspaceId, setWorkspaceId] = useState('');
  const [inviteToken, setInviteToken] = useState('');
  const [error, setError] = useState('');
  const auth = getAuth();
  const db = getFirestore();
  const { setCurrentUser } = useAuth();

  const fetchInviteDetails = useCallback(async (invite, workspace) => {
    try {
      console.log(`Fetching details for invite: ${invite}, workspace: ${workspace}`);
      const workspaceDoc = await getDoc(doc(db, 'workspaces', workspace));
      if (workspaceDoc.exists()) {
        console.log('Workspace found:', workspaceDoc.data());
        const members = workspaceDoc.data().members;
        const member = members.find((m) => m.inviteToken === invite);
        if (member) {
          console.log('Member found:', member);
          setInviteToken(invite);
          setWorkspaceId(workspace);
        } else {
          setError('Invalid invite token or workspace ID.');
          console.log('Invalid invite token or workspace ID.');
        }
      } else {
        setError('Workspace not found.');
        console.log('Workspace not found.');
      }
    } catch (err) {
      setError('Error checking invite: ' + err.message);
      console.error('Error checking invite:', err.message);
    }
  }, [db]);

  useEffect(() => {
    const invite = searchParams.get('invite');
    const workspace = searchParams.get('workspace');
    if (invite && workspace) {
      console.log('Invite token:', invite);
      console.log('Workspace ID:', workspace);
      fetchInviteDetails(invite, workspace);
    }
  }, [searchParams, fetchInviteDetails]);

  const handleSignup = async () => {
    try {
      console.log('Signing up user with email:', email);
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log('User created:', user.uid);

      // Create user's personal workspace
      const personalWorkspaceRef = await addDoc(collection(db, 'workspaces'), {
        name: "My Workspace",
        createdAt: serverTimestamp(),
        ownerId: user.uid,
        planDetails: [
            {
              plan: "trial",
              status: "none",
            },
          ],
        members: [
          {
            userId: user.uid,
            email: user.email,
            createdAt: new Date(),
            status: 'accepted',
            role: 'owner',
          },
        ],
      });

      // Add the user to the invited workspace if not already a member
      const workspaceRef = doc(db, 'workspaces', workspaceId);
      const workspaceDoc = await getDoc(workspaceRef);
      if (workspaceDoc.exists()) {
        const members = workspaceDoc.data().members;
        if (!members.find((m) => m.email === email)) {
          await updateDoc(workspaceRef, {
            members: arrayUnion({
              email,
              userId: user.uid,
              status: 'accepted',
              role: 'member',
              inviteToken: inviteToken,
              createdAt: new Date(),
            }),
          });
        }
      }

      // Add the user to the 'users' collection with both workspaces
      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        uid: user.uid,
        createdAt: serverTimestamp(),
        workspaces: [workspaceId, personalWorkspaceRef.id],
      });

      // Accept the invite
      await axios.post('https://api.clientbase.dev/accept-invite', {
        token: inviteToken,
        email,
        userId: user.uid,
        workspaceId,
      });

      if (setCurrentUser) setCurrentUser(user);
      navigate(`/dashboard/${workspaceId}`);
    } catch (err) {
      console.error('Error signing up:', err.message);
      setError('Error signing up: ' + err.message);
    }
  };

  const handleLogin = async () => {
    try {
      console.log('Logging in user with email:', email);
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log('User logged in:', user.uid);

      // Add the user to the invited workspace if not already a member
      const workspaceRef = doc(db, 'workspaces', workspaceId);
      const workspaceDoc = await getDoc(workspaceRef);
      if (workspaceDoc.exists()) {
        const members = workspaceDoc.data().members;
        if (!members.find((m) => m.email === email)) {
          await updateDoc(workspaceRef, {
            members: arrayUnion({
              email,
              userId: user.uid,
              status: 'accepted',
              role: 'member',
              inviteToken: inviteToken,
              createdAt: new Date(),
            }),
          });
        }
      }

      // Add the workspace to the user's workspaces array if not already present
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (!userData.workspaces.includes(workspaceId)) {
          await updateDoc(userRef, {
            workspaces: arrayUnion(workspaceId),
          });
        }
      }

      // Accept the invite
      await axios.post('https://api.clientbase.dev/accept-invite', {
        token: inviteToken,
        email,
        userId: user.uid,
        workspaceId,
      });

      if (setCurrentUser) setCurrentUser(user);
      navigate(`/dashboard/${workspaceId}`);
    } catch (err) {
      console.error('Error logging in:', err.message);
      setError('Error logging in: ' + err.message);
    }
  };

  return (
    <Container centerContent>
      <Heading>{isNewUser ? 'Sign Up' : 'Log In'}</Heading>
      <VStack spacing={4} mt={6}>
        {error && <Text color="red.500">{error}</Text>}
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button onClick={isNewUser ? handleSignup : handleLogin}>
          {isNewUser ? 'Sign Up' : 'Log In'}
        </Button>
        <Text>
          {isNewUser ? (
            <Link color="teal.500" onClick={() => setIsNewUser(false)}>Click here to Log In</Link>
          ) : (
            <Link color="teal.500" onClick={() => setIsNewUser(true)}>Click here to Sign Up</Link>
          )}
        </Text>
      </VStack>
    </Container>
  );
};

export default HandleInvite;
