import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyBMSiRtl35YnrUTF72N1NrHIoPmLAAIzPE",
    authDomain: "clientbasedevelopment.firebaseapp.com",
    projectId: "clientbasedevelopment",
    storageBucket: "clientbasedevelopment.appspot.com",
    messagingSenderId: "925295630112",
    appId: "1:925295630112:web:6e7507cc9403a858bde945"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

export { auth, db, functions };
