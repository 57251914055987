import React, { useState, useEffect } from 'react';
import { Box, Input, VStack, Text, Avatar, HStack, Menu, MenuButton, MenuList, MenuItem, Button } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../Firebase';
import { Portal } from 'react-portal';

const WorkspaceDropdown = () => {
  const [workspaces, setWorkspaces] = useState([]);
  const [filteredWorkspaces, setFilteredWorkspaces] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const db = getFirestore();
  const user = auth.currentUser;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWorkspaces = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const workspaceDataPromises = userData.workspaces.map(async (workspaceId) => {
            const workspaceDoc = await getDoc(doc(db, 'workspaces', workspaceId));
            return { id: workspaceId, ...workspaceDoc.data() };
          });
          const workspacesData = await Promise.all(workspaceDataPromises);
          setWorkspaces(workspacesData);
          setFilteredWorkspaces(workspacesData);
          if (workspacesData.length > 0) {
            setSelectedWorkspace(workspacesData[0]);
          }
        }
      }
    };

    fetchWorkspaces();
  }, [db, user]);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    const filtered = workspaces.filter(workspace => workspace.name.toLowerCase().includes(searchValue));
    setFilteredWorkspaces(filtered);
  };

  const handleWorkspaceSelect = (workspace) => {
    setSelectedWorkspace(workspace);
    navigate(`/dashboard/${workspace.id}/overview`);
    window.location.reload();
  };

  return (
    <Box position="relative" zIndex="1" width="100%">
      <Menu>
        <MenuButton as={Button} width="100%" rightIcon={<ChevronDownIcon />} textAlign="left">
          <Box isTruncated>
            {selectedWorkspace ? selectedWorkspace.name : 'Select Workspace'}
          </Box>
        </MenuButton>
        <Portal>
          <MenuList maxWidth="400px" bg="white" zIndex="1000">
            <Box px="4" py="2">
              <Input placeholder="Search workspace" onChange={handleSearchChange} />
            </Box>
            {filteredWorkspaces.map((workspace) => (
              <MenuItem key={workspace.id} onClick={() => handleWorkspaceSelect(workspace)}>
                <HStack>
                  <Avatar size="sm" name={workspace.name.charAt(0).toUpperCase()} />
                  <VStack align="start" spacing="0">
                    <Text>{workspace.name}</Text>
                    <Text fontSize="sm" color="gray.500">Role: {workspace.members.find(member => member.userId === user.uid)?.role || 'Unknown'}</Text>
                  </VStack>
                </HStack>
              </MenuItem>
            ))}
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  );
};

export default WorkspaceDropdown;
