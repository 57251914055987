import React, { useEffect, useState, useCallback } from "react";
import {
  VStack,
  Flex,
  IconButton,
  Text,
  Box,
  useToast,
  Tooltip,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Link,
} from "@chakra-ui/react";
import { ChevronUpIcon, ChevronDownIcon, RepeatIcon } from "@chakra-ui/icons";
import { FaEnvelope } from "react-icons/fa";
import { db } from "../../../../Firebase";
import { doc, collection, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import Buttons from "../Buttons"; // Import the new component

const Submissions = () => {
  const { workspaceId, agentId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [submissions, setSubmissions] = useState([]);
  const [agentName, setAgentName] = useState("");
  const [sortOrder, setSortOrder] = useState("newest");
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedSubmission, setSelectedSubmission] = useState(null);

  const fetchAgentData = useCallback(async () => {
    if (currentUser && workspaceId && agentId) {
      const agentDocRef = doc(db, "workspaces", workspaceId, "agents", agentId);
      onSnapshot(agentDocRef, (agentDoc) => {
        if (agentDoc.exists()) {
          const agentData = agentDoc.data();
          setAgentName(agentData.name || "Unnamed Agent");
        } else {
          toast({
            title: "Agent not found",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          navigate(-1);
        }
      });

      const submissionsColRef = collection(agentDocRef, "submissions");
      const submissionsSnapshot = await getDocs(submissionsColRef);
      if (submissionsSnapshot.empty) {
        setSubmissions([]);
      } else {
        const submissionsList = submissionsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSubmissions(submissionsList);
      }
    }
  }, [currentUser, workspaceId, agentId, navigate, toast]);

  useEffect(() => {
    fetchAgentData();
  }, [fetchAgentData]);

  const formatPhoneNumber = useCallback((phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    if (cleaned.length === 10) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
    } else if (cleaned.length === 11) {
      return `+${cleaned.slice(0, 1)} (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7)}`;
    }
    return phoneNumber;
  }, []);

  const handlePhoneNumberClick = async (phoneNumber) => {
    const contactsRef = collection(db, `workspaces/${workspaceId}/contacts`);
    const q = query(contactsRef, where("phoneNumber", "==", phoneNumber));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        navigate(`/dashboard/${workspaceId}/contacts/${doc.id}`);
      });
    } else {
      toast({
        title: "No customer exists",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const sortedEntries = (submission) => {
    const compareFields = (a, b) => {
      const aMatch = a.match(/^(.*?)(\d+)$/);
      const bMatch = b.match(/^(.*?)(\d+)$/);

      if (aMatch && bMatch) {
        const [, aPrefix, aNum] = aMatch;
        const [, bPrefix, bNum] = bMatch;

        if (aPrefix === bPrefix) {
          return parseInt(aNum) - parseInt(bNum);
        }
      }

      return a.localeCompare(b);
    };

    return Object.entries(submission)
      .filter(([key]) => key !== 'id' && key !== 'createdDate' && key !== 'response')
      .sort((a, b) => compareFields(a[0], b[0]));
  };

  const setSortOrderNewest = () => setSortOrder("newest");
  const setSortOrderOldest = () => setSortOrder("oldest");

  const sortedSubmissions = [...submissions].sort((a, b) => {
    const dateA = new Date(a.createdDate);
    const dateB = new Date(b.createdDate);
    return sortOrder === "newest" ? dateB - dateA : dateA - dateB;
  });

  const handleRefresh = () => {
    fetchAgentData();
    toast({
      title: "Refreshed submissions",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const handleGridItemClick = (submission) => {
    setSelectedSubmission(submission);
    onOpen();
  };

  return (
    <VStack spacing={4} align="stretch" p={4}>
      <Flex justify="space-between" align="center">
        <Buttons workspaceId={workspaceId} agentId={agentId} />
        <Flex align="center">
          <Text fontSize="2xl" fontWeight="bold" mr={4}>
            {agentName}
          </Text>
          <Tooltip label="Sort Newest First" placement="top" hasArrow>
            <IconButton
              icon={<ChevronUpIcon boxSize={6} />}
              variant="ghost"
              onClick={setSortOrderNewest}
              aria-label="Sort Newest First"
              bg={sortOrder === "newest" ? "gray.200" : "white"}
              borderColor="gray.200"
              borderWidth="2px"
              color="gray.500"
              size="md"
              mr={2}
            />
          </Tooltip>
          <Tooltip label="Sort Oldest First" placement="top" hasArrow>
            <IconButton
              icon={<ChevronDownIcon boxSize={6} />}
              variant="ghost"
              onClick={setSortOrderOldest}
              aria-label="Sort Oldest First"
              bg={sortOrder === "oldest" ? "gray.200" : "white"}
              borderColor="gray.200"
              borderWidth="2px"
              color="gray.500"
              size="md"
              mr={2}
            />
          </Tooltip>
          <Tooltip label="Refresh Submissions" placement="top" hasArrow>
            <IconButton
              icon={<RepeatIcon boxSize={6} />}
              variant="ghost"
              onClick={handleRefresh}
              aria-label="Refresh Submissions"
              bg="white"
              borderColor="gray.200"
              borderWidth="2px"
              color="gray.500"
              size="md"
            />
          </Tooltip>
        </Flex>
      </Flex>

      <SimpleGrid columns={[2, 3, 4, 5]} spacing={4}>
        {sortedSubmissions.map((submission) => (
          <Box
            key={submission.id}
            borderWidth="1px"
            borderRadius="lg"
            p={4}
            cursor="pointer"
            onClick={() => handleGridItemClick(submission)}
            _hover={{ backgroundColor: "gray.100" }}
          >
            <Flex align="center" mb={2}>
              <FaEnvelope />
              <Text fontSize="lg" fontWeight="bold" ml={2}>
                Submission
              </Text>
            </Flex>
            <Text fontSize="sm" color="gray.500" mb={2}>
              {formatDate(submission.createdDate)}
            </Text>
            {sortedEntries(submission).slice(0, 2).map(([key, value]) => (
              <Text key={key} fontSize="sm">
                <strong>{key}:</strong> {typeof value === 'object' ? JSON.stringify(value) : value}
              </Text>
            ))}
          </Box>
        ))}
      </SimpleGrid>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Submission Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedSubmission && (
              <VStack align="stretch" spacing={3}>
                {sortedEntries(selectedSubmission).map(([key, value]) => (
                  <Box key={key}>
                    <Text fontWeight="bold">{key}:</Text>
                    {key === 'responseId' ? (
                      <Link color="blue.500" href={`/dashboard/${workspaceId}/call-logs/${value}`}>
                        {value}
                      </Link>
                    ) : (
                      <Text>
                        {typeof value === 'object' ? JSON.stringify(value) : (
                          value.match(/^\+?[1-9]\d{1,14}$/) ? (
                            <Link
                              color="blue.500"
                              onClick={() => handlePhoneNumberClick(value)}
                            >
                              {formatPhoneNumber(value)}
                            </Link>
                          ) : value
                        )}
                      </Text>
                    )}
                  </Box>
                ))}
              </VStack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Submissions;