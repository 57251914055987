// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();
  const location = useLocation();

  if (!currentUser && !['/login', '/signup', '/auth'].includes(location.pathname)) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
