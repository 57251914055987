import React, { createContext, useContext, useEffect, useRef, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { applyNodeChanges, applyEdgeChanges, addEdge } from 'reactflow';
import { doc, updateDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../../Firebase';

const NodeContext = createContext();

export const useNodeContext = () => useContext(NodeContext);

export const NodeProvider = ({ children }) => {
  const { workspaceId, agentId } = useParams();
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [agentName, setAgentName] = useState('');
  const [initialGraph, setInitialGraph] = useState({ nodes: [], edges: [] });
  const intervalRef = useRef(null);

  useEffect(() => {
    const agentRef = doc(db, 'workspaces', workspaceId, 'agents', agentId);
    const unsubscribe = onSnapshot(agentRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const agentData = docSnapshot.data();
        const { graph = { nodes: [], edges: [] } } = agentData;
        setNodes(graph.nodes);
        setEdges(graph.edges);
        setAgentName(agentData.name || '');
        setInitialGraph(graph);
      }
    });

    return () => unsubscribe();
  }, [workspaceId, agentId]);

  const saveGraphData = useCallback(
    async (newNodes, newEdges = edges) => {
      const agentRef = doc(db, 'workspaces', workspaceId, 'agents', agentId);
      try {
        await updateDoc(agentRef, {
          graph: { nodes: newNodes, edges: newEdges },
          name: agentName,
        });
        console.log('Graph data saved to Firestore');
      } catch (error) {
        console.error('Error saving graph data to Firestore: ', error);
      }
    },
    [workspaceId, agentId, agentName, edges]
  );

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const hasChanged =
        JSON.stringify({ nodes, edges }) !== JSON.stringify(initialGraph);
      if (hasChanged) {
        saveGraphData(nodes, edges);
        setInitialGraph({ nodes, edges });
      }
    }, 2000);

    return () => clearInterval(intervalRef.current);
  }, [nodes, edges, initialGraph, saveGraphData]);

  const handleAddNode = async (newNode) => {
    const newNodes = [...nodes, newNode];
    setNodes(newNodes);
    setInitialGraph({ ...initialGraph, nodes: newNodes });
    await saveGraphData(newNodes);
  };

  const onNodesChange = (changes) => {
    const updatedNodes = applyNodeChanges(changes, nodes);
    setNodes(updatedNodes);
  };

  const onEdgesChange = (changes) => {
    const updatedEdges = applyEdgeChanges(changes, edges);
    setEdges(updatedEdges);
  };

  const onConnect = (params) => {
    const newEdges = addEdge(params, edges);
    setEdges(newEdges);
  };

  const onNodeDragStop = (event, node) => {
    const updatedNodes = nodes.map((n) => (n.id === node.id ? node : n));
    setNodes(updatedNodes);
  };

  const handleAgentNameChange = async (name) => {
    setAgentName(name);
    const agentRef = doc(db, 'workspaces', workspaceId, 'agents', agentId);
    try {
      await updateDoc(agentRef, { name });
      console.log('Agent name updated in Firestore');
    } catch (error) {
      console.error('Error updating agent name in Firestore: ', error);
    }
  };

  const deleteNode = async (nodeId) => {
    const newNodes = nodes.filter((node) => node.id !== nodeId);
    const newEdges = edges.filter(
      (edge) => edge.source !== nodeId && edge.target !== nodeId
    );
    setNodes(newNodes);
    setEdges(newEdges);
    setSelectedNode(null);
    await saveGraphData(newNodes, newEdges);
  };

  return (
    <NodeContext.Provider
      value={{
        nodes,
        edges,
        handleAddNode,
        onNodesChange,
        onEdgesChange,
        onConnect,
        onNodeDragStop,
        agentName,
        handleAgentNameChange,
        selectedNode,
        setSelectedNode,
        saveGraphData,
        setNodes,
        deleteNode,
      }}
    >
      {children}
    </NodeContext.Provider>
  );
};
