import React, { useEffect, useState } from 'react';
import { Heading, Button, Box, Text, Badge } from '@chakra-ui/react';
import { functions, auth } from '../../../../Firebase';
import { httpsCallable } from 'firebase/functions';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const monthlyPricingPlan = [
  {
    name: 'Lite Plan',
    monthlyPriceId: 'price_1Pml73ANnNx410iFB1ZJgn4A',
    unitPriceId: 'price_1Pml7zANnNx410iFumYaFMVo',
    price: '$595/month',
    features: ['2,000 Minutes', '2 Agents', '1 Phone Number', '25¢ per extra minute'],
    totalMinutes: 2000
  },
  {
    name: 'Professional Plan',
    monthlyPriceId: 'price_1Pml7YANnNx410iFtjdlKnRZ',
    unitPriceId: 'price_1Pml8IANnNx410iFSm59jFKL',
    price: '$1,295/month',
    features: ['6,000 Minutes', 'Unlimited Agents', '2 Phone Numbers', '25¢ per extra minute'],
    totalMinutes: 6000
  },
];

const yearlyPricingPlan = [
  {
    name: 'Lite Plan',
    monthlyPriceId: 'price_1Pml9AANnNx410iF2021eOLl',
    unitPriceId: 'price_1PmlABANnNx410iFa2SJZ4cu',
    price: '$5,940/year',
    features: ['2,000 Minutes', '2 Agents', '1 Phone Number', '25¢ per extra minute'],
    totalMinutes: 24000
  },
  {
    name: 'Professional Plan',
    monthlyPriceId: 'price_1Pml9hANnNx410iFfuy253br',
    unitPriceId: 'price_1PmlAPANnNx410iFpEv1xzqx',
    price: '$11,940/year',
    features: ['6,000 Minutes', 'Unlimited Agents', '2 Phone Numbers', '25¢ per extra minute'],
    totalMinutes: 72000
  },
];

const PlanDetails = () => {
  const { workspaceId } = useParams();
  const [workspaceCustomerId, setWorkspaceCustomerId] = useState('');
  const [workspaceStatus, setWorkspaceStatus] = useState('');
  const [planStartDate, setPlanStartDate] = useState(null);
  const [planEndDate, setPlanEndDate] = useState(null);
  const [loadingPortal, setLoadingPortal] = useState(false);
  const [planName, setPlanName] = useState('');
  const db = getFirestore();

  useEffect(() => {
    const fetchWorkspaceDetails = async () => {
      if (auth.currentUser) {
        const workspaceDoc = await getDoc(doc(db, 'workspaces', workspaceId));
        if (workspaceDoc.exists()) {
          const workspaceData = workspaceDoc.data();
          setWorkspaceCustomerId(workspaceData.customerId || '');
          setWorkspaceStatus(workspaceData.status || 'Unknown');
          if (workspaceData.planStartDate) {
            setPlanStartDate(new Date(workspaceData.planStartDate * 1000));
          }
          if (workspaceData.planEndDate) {
            setPlanEndDate(new Date(workspaceData.planEndDate * 1000));
          }
          
          // Determine the plan name
          const planPriceId = workspaceData.planPriceIds?.[0];
          if (planPriceId) {
            const monthlyPlan = monthlyPricingPlan.find(plan => plan.monthlyPriceId === planPriceId);
            const yearlyPlan = yearlyPricingPlan.find(plan => plan.monthlyPriceId === planPriceId);
            if (monthlyPlan) {
              setPlanName(`${monthlyPlan.name} (Monthly)`);
            } else if (yearlyPlan) {
              setPlanName(`${yearlyPlan.name} (Yearly)`);
            } else {
              setPlanName('Free Plan');
            }
          }
        }
      }
    };

    fetchWorkspaceDetails();
  }, [db, workspaceId]);

  const handleBillingPortal = async (customerId) => {
    if (!customerId) {
      console.error('Error: No Stripe customer ID found.');
      return;
    }

    setLoadingPortal(true);

    const createBillingPortalSession = httpsCallable(functions, 'createBillingPortalSession');
    const returnUrl = `${window.location.origin}/dashboard/${workspaceId}`;

    try {
      const result = await createBillingPortalSession({ customerId, returnUrl });
      const session = result.data;
      window.location.href = session.url;
    } catch (error) {
      console.error('Error:', error);
      setLoadingPortal(false);
    }
  };

  const getStatusBadgeColor = (status) => {
    switch (status.toLowerCase()) {
      case 'free':
        return 'gray';
      case 'trialing':
        return 'orange';
      case 'active':
        return 'green';
      default:
        return 'gray';
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Box>
      <Heading mb={4}>Plan Billing Settings</Heading>
      <Text mb={2}>You are on the <strong>{planName}</strong></Text>
      <Text mb={2}>
        Plan Status: <Badge colorScheme={getStatusBadgeColor(workspaceStatus)} ml={2}>
          {capitalizeFirstLetter(workspaceStatus)}
        </Badge>
      </Text>
      {planStartDate && (
        <Text mb={2}>Plan Start Date: <strong>{planStartDate.toLocaleString()}</strong></Text>
      )}
      {planEndDate && (
        <Text mb={2}>Plan End Date: <strong>{planEndDate.toLocaleString()}</strong></Text>
      )}
      {workspaceCustomerId && (
        <Button
          colorScheme="blue"
          onClick={() => handleBillingPortal(workspaceCustomerId)}
          mb={8}
          isDisabled={loadingPortal}
        >
          {loadingPortal ? 'Please Wait' : 'Go to Billing Portal'}
        </Button>
      )}
    </Box>
  );
};

export default PlanDetails;