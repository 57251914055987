import React, { useState, useEffect } from 'react';
import { Box, VStack, Heading, Text, Flex, Center, Container, Fade } from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';

const reviews = [
  { id: 1, text: "ClientbaseAI has revolutionized our customer management!", author: "John D., CEO" },
  { id: 2, text: "The best CRM solution we've ever used. Highly recommended!", author: "Sarah L., Marketing Director" },
  { id: 3, text: "Incredible AI-powered insights. It's like having a data scientist on the team!", author: "Mike R., Sales Manager" },
];

const RightSideContent = () => {
  const [currentReview, setCurrentReview] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsVisible(false);
      setTimeout(() => {
        setCurrentReview((prev) => (prev + 1) % reviews.length);
        setIsVisible(true);
      }, 500); // Wait for fade out to complete before changing review
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Center height="100%" bg="#222222">
      <Container maxW="800px" p={8} color="white">
        <VStack spacing={10} align="center" textAlign="center">
          <Heading size="3xl">
            Transform Your Customer Relationships With Voice
          </Heading>
          
          <VStack align="center" spacing={4}>
            <Flex flexWrap="wrap" justifyContent="center" gap={4}>
              {["AI-Driven Insights", "Seamless Integration", "Customizable Workflows", "Real-time Analytics", "24/7 Customer Support"].map((item, index) => (
                <Box key={index} bg="blue.700" px={4} py={2} borderRadius="full">
                  <Text fontWeight="bold">✓ {item}</Text>
                </Box>
              ))}
            </Flex>
          </VStack>

          <Box bg="#2d2d2d" p={6} borderRadius="xl" boxShadow="xl" width="100%" height="200px">
            <Heading size="md" mb={4} color="blue.300">What Our Clients Say:</Heading>
            <Fade in={isVisible} unmountOnExit>
              <Box
                 // Fixed height to prevent layout shift
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Text fontSize="lg" fontStyle="italic" mb={4}>"{reviews[currentReview].text}"</Text>
                <Text fontWeight="bold" mb={2}>- {reviews[currentReview].author}</Text>
              </Box>
              <Flex justify="center">
              {[...Array(5)].map((_, i) => (
                <StarIcon key={i} color="yellow.400" w={6} h={6} />
              ))}
            </Flex>
            </Fade>
          </Box>
        </VStack>
      </Container>
    </Center>
  );
};

export default RightSideContent;