import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Text,
  Flex,
  Icon,
  Spacer,
  Textarea,
  FormLabel,
} from '@chakra-ui/react';
import { CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import { FaComments } from 'react-icons/fa';
import { useNodeContext } from '../NodeContext';

const ConversationSidebar = ({ onClose }) => {
  const { selectedNode, setSelectedNode, nodes, setNodes, saveGraphData, edges, deleteNode } = useNodeContext();
  const [intent, setIntent] = useState('');
  const [conversationText, setConversationText] = useState('');

  useEffect(() => {
    if (selectedNode && selectedNode.type === 'conversationNode') {
      setIntent(selectedNode.data.intent || '');
      setConversationText(selectedNode.data.conversationText || '');
    }
  }, [selectedNode]);

  const handleIntentChange = (e) => {
    const updatedIntent = e.target.value;
    setIntent(updatedIntent);

    const updatedNodes = nodes.map((node) =>
      node.id === selectedNode.id ? { ...node, data: { ...node.data, intent: updatedIntent } } : node
    );
    setNodes(updatedNodes);
    setSelectedNode({ ...selectedNode, data: { ...selectedNode.data, intent: updatedIntent } });

    saveGraphData(updatedNodes, edges);
  };

  const handleConversationTextChange = (e) => {
    const updatedText = e.target.value;
    setConversationText(updatedText);

    const updatedNodes = nodes.map((node) =>
      node.id === selectedNode.id ? { ...node, data: { ...node.data, conversationText: updatedText } } : node
    );
    setNodes(updatedNodes);
    setSelectedNode({ ...selectedNode, data: { ...selectedNode.data, conversationText: updatedText } });

    saveGraphData(updatedNodes, edges);
  };

  const handleClose = () => {
    setSelectedNode(null);
    onClose();
  };

  const handleDelete = () => {
    deleteNode(selectedNode.id);
    handleClose();
  };

  return (
    <Box
      position="absolute"
      top="0"
      right="0"
      width="500px"
      height="100%"
      backgroundColor="white"
      p={4}
      zIndex={10}
      borderLeft="1px"
      borderLeftColor="gray.200"
    >
      {selectedNode && selectedNode.type === 'conversationNode' && (
        <Box>
          <Flex alignItems="center">
            <Icon as={FaComments} w={6} h={6} color="#f07b0e" />
            <Text fontSize="xl" color="gray.500" ml={2}>
              CONVERSATION NODE
            </Text>
            <Spacer />
            <IconButton mr={4} icon={<CloseIcon />} onClick={handleClose} />
            <IconButton icon={<DeleteIcon />} colorScheme="red" onClick={handleDelete} />
          </Flex>
          <Box mt={4}>
            <FormLabel>Intent</FormLabel>
            <Textarea
              placeholder="What is the intent of this message."
              value={intent}
              onChange={handleIntentChange}
            />
          </Box>
          <Box mt={4}>
            <FormLabel>Conversation Text</FormLabel>
            <Textarea
              placeholder="Enter the conversation text for this intent."
              value={conversationText}
              onChange={handleConversationTextChange}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ConversationSidebar;
