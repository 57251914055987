import React from "react";
import { Box, Heading, Center, Spinner, Flex } from "@chakra-ui/react";
import { useAuth } from "../AuthContext";
import { useParams } from "react-router-dom";
import CallLogsSearch from "./searchcomponents/CallLogsSearch";
import ContactSearch from "./searchcomponents/ContactSearch";

const Search = () => {
  const { currentUser } = useAuth();
  const { workspaceId } = useParams();

  return (
    <Box p={4}>
      <Heading as="h1" size="xl" mb={4}>
        Search
      </Heading>
      {!currentUser ? (
        <Center height="200px">
          <Spinner />
        </Center>
      ) : (
        <Flex>
          <Box flex={1} mr={4}>
            <CallLogsSearch currentUser={currentUser} />
          </Box>
          <Box flex={1}>
            <ContactSearch workspaceId={workspaceId} />
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default Search;