import React, { useEffect, useState } from 'react';
import { Heading, Select, Flex, VStack, HStack, Box, Stat, StatLabel, StatNumber, StatGroup } from '@chakra-ui/react';
import { useAuth } from '../AuthContext';
import getUserWorkspaces from '../utils/getUserWorkspaces';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { FaPhoneAlt, FaDollarSign, FaRegClock } from 'react-icons/fa';
import { FiGrid } from 'react-icons/fi';
import { useSpring, animated } from 'react-spring';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const AnimatedNumber = ({ value, prefix = '', isWholeNumber = false, formatter }) => {
  const { number } = useSpring({
    from: { number: 0 },
    number: value,
    delay: 200,
    config: { mass: 1, tension: 280, friction: 120 },
  });

  return <animated.div>{number.to(n => (isWholeNumber ? `${prefix}${Math.round(n)}` : formatter ? formatter(Math.round(n)) : `${prefix}${n.toFixed(2)}`))}</animated.div>;
};

const Overview = () => {
  const { currentUser } = useAuth();
  const [workspaces, setWorkspaces] = useState([]);
  const [selectedOption, setSelectedOption] = useState('7 days');
  const [usageData, setUsageData] = useState([]);
  const [stats, setStats] = useState({
    averageCallDuration: 0,
    totalCalls: 0,
    moneySaved: 0,
    timeSaved: 0,
  });
  const [showCharts, setShowCharts] = useState(true);

  useEffect(() => {
    const fetchWorkspaces = async () => {
      if (currentUser) {
        const userWorkspaces = await getUserWorkspaces(currentUser.uid);
        setWorkspaces(userWorkspaces);
      }
    };

    fetchWorkspaces();
  }, [currentUser]);

  useEffect(() => {
    const fetchUsageData = async () => {
      if (workspaces.length > 0) {
        const db = getFirestore();
        const usageRef = collection(db, 'workspaces', workspaces[0], 'usage');
        
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - (selectedOption === '7 days' ? 7 : 30));
        
        const q = query(usageRef, where('date', '>=', startDate.toISOString().split('T')[0]));
        const querySnapshot = await getDocs(q);
        
        const fetchedData = querySnapshot.docs.map(doc => ({
          date: doc.id,
          ...doc.data()
        }));

        const allDates = [];
        for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
          allDates.push(d.toISOString().split('T')[0]);
        }

        const mergedData = allDates.map(date => {
          const existingData = fetchedData.find(item => item.date === date);
          return existingData || { date, calls: [] };
        });

        setUsageData(mergedData);

        let totalDuration = 0;
        let totalCalls = 0;

        mergedData.forEach(day => {
          if (day.calls) {
            totalCalls += day.calls.length;
            totalDuration += day.calls.reduce((total, call) => total + (call.secondsFromStart || 0), 0);
          }
        });

        totalDuration = totalDuration / 1000;

        const averageCallDuration = totalCalls > 0 ? totalDuration / totalCalls : 0;
        const moneySaved = totalCalls * 5;
        const timeSaved = totalCalls * 8;

        setStats({
          averageCallDuration,
          totalCalls,
          moneySaved,
          timeSaved,
        });
      }
    };

    fetchUsageData();
  }, [workspaces, selectedOption]);

  const handleResize = () => {
    setShowCharts(false);
    setTimeout(() => setShowCharts(true), 300);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(document.body);
    
    return () => {
      window.removeEventListener('resize', handleResize);
      resizeObserver.disconnect();
    };
  }, []);

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.round(seconds % 60);
    return `${minutes}m ${remainingSeconds}s`;
  };

  const formatTimeSaved = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  const callsPerDayData = {
    labels: usageData.map(day => formatDate(day.date)),
    datasets: [
      {
        label: 'Calls per Day',
        data: usageData.map(day => day.calls ? day.calls.length : 0),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.1)',
        tension: 0.4,
        fill: true,
        pointRadius: 4,
        pointHoverRadius: 6,
      },
    ],
  };

  const avgCallDurationData = {
    labels: usageData.map(day => formatDate(day.date)),
    datasets: [
      {
        label: 'Average Call Duration (minutes)',
        data: usageData.map(day => {
          if (day.calls && day.calls.length > 0) {
            const totalDuration = day.calls.reduce((total, call) => total + (call.secondsFromStart || 0), 0);
            return (totalDuration / day.calls.length / 60000).toFixed(2);
          }
          return 0;
        }),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.1)',
        tension: 0.4,
        fill: true,
        pointRadius: 4,
        pointHoverRadius: 6,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            family: 'Reddit Sans',
            size: 14,
          },
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        titleColor: '#333',
        bodyColor: '#666',
        borderColor: '#ccc',
        borderWidth: 1,
        padding: 10,
        bodyFont: {
          family: 'Reddit Sans',
        },
        titleFont: {
          family: 'Reddit Sans',
          weight: 'bold',
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: 'Reddit Sans',
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          font: {
            family: 'Reddit Sans',
          },
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
    },
  };

  return (
    <VStack align="stretch" spacing={6} p={4} borderRadius="lg">
      <Flex alignItems="center">
        <FiGrid size={30} style={{ marginRight: '12px' }} />
        <Heading as="h2" size="xl" mr={4}>
          Overview
        </Heading>
        <Select
          width="150px"
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
          bg="white"
        >
          <option value="7 days">Last 7 days</option>
          <option value="30 days">Last 30 days</option>
        </Select>
      </Flex>
      <StatGroup gap={4}>
        <Stat borderWidth='1px' borderRadius="md" p={4}>
          <StatLabel display="flex" alignItems="center" fontWeight="bold" color="green.500" fontSize="20px" mb={4}>
            <FaDollarSign style={{ fontSize: '1.2em', marginRight: '0.5em' }} />
            Money Saved
          </StatLabel>
          <StatNumber fontSize="32px"><AnimatedNumber value={stats.moneySaved} prefix="$" /></StatNumber>
        </Stat>
        <Stat borderWidth='1px' borderRadius="md" p={4}>
          <StatLabel display="flex" alignItems="center" fontWeight="bold" color="blue.500" fontSize="20px" mb={4}>
            <FaRegClock style={{ fontSize: '1.2em', marginRight: '0.5em' }} />
            Time Saved
          </StatLabel>
          <StatNumber fontSize="32px"><AnimatedNumber value={stats.timeSaved} formatter={formatTimeSaved} /></StatNumber>
        </Stat>
        <Stat borderWidth='1px' borderRadius="md" p={4}>
          <StatLabel display="flex" alignItems="center" fontWeight="bold" color="teal.500" fontSize="20px" mb={4}>
            <FaRegClock style={{ fontSize: '1.2em', marginRight: '0.5em' }} />
            Avg. Call Duration
          </StatLabel>
          <StatNumber fontSize="32px"><AnimatedNumber value={stats.averageCallDuration} formatter={formatDuration} /></StatNumber>
        </Stat>
        <Stat borderWidth='1px' borderRadius="md" p={4}>
          <StatLabel display="flex" alignItems="center" fontWeight="bold" color="purple.500" fontSize="20px" mb={4}>
            <FaPhoneAlt style={{ fontSize: '1.2em', marginRight: '0.5em' }} />
            Number of Calls
          </StatLabel>
          <StatNumber fontSize="32px"><AnimatedNumber value={stats.totalCalls} isWholeNumber={true} /></StatNumber>
        </Stat>
      </StatGroup>
      <HStack spacing={6} align="start">
        <Box flex={1} bg="white" p={4} borderRadius="md" borderWidth="1px">
          <Heading>
            Total Calls Per Day
          </Heading>
          {showCharts && <Line data={callsPerDayData} options={chartOptions} />}
        </Box>
        <Box flex={1} bg="white" p={4} borderRadius="md" borderWidth="1px">
          <Heading>
            Avg. Call Duration
          </Heading>
          {showCharts && <Line data={avgCallDurationData} options={chartOptions} />}
        </Box>
      </HStack>
    </VStack>
  );
};

export default Overview;
