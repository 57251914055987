// components/Notifications.js
import React from 'react';
import { Container, Heading } from '@chakra-ui/react';

const Notifications = () => {
  return (
    <Container centerContent>
      <Heading>Notifications Settings</Heading>
    </Container>
  );
};

export default Notifications;