import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Heading,
  Text,
  Flex,
  Input,
  VStack,
  InputGroup,
  InputLeftElement,
  Icon,
  Spinner,
  Badge,
  HStack
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useAuth } from '../AuthContext';
import getUserWorkspaces from '../utils/getUserWorkspaces';
import { getFirestore, collection, onSnapshot } from 'firebase/firestore';
import { useNavigate, Outlet } from 'react-router-dom';
import { FiMessageSquare } from 'react-icons/fi';

const Messages = () => {
  const { currentUser } = useAuth();
  const [messages, setMessages] = useState([]);
  const [workspaceId, setWorkspaceId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchMessages = useCallback(async () => {
    if (currentUser) {
      try {
        const workspaces = await getUserWorkspaces(currentUser.uid);
        if (workspaces.length > 0) {
          const workspaceId = workspaces[0];
          setWorkspaceId(workspaceId);
          console.log('Workspace ID:', workspaceId);

          const db = getFirestore();
          const messagesQuery = collection(db, 'workspaces', workspaceId, 'messages');

          onSnapshot(messagesQuery, (snapshot) => {
            const messagesData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            console.log('Messages:', messagesData);
            setMessages(messagesData);
            setFilteredMessages(messagesData);
            setLoading(false);
          });
        }
      } catch (error) {
        console.error('Error fetching messages:', error);
        setLoading(false);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  useEffect(() => {
    const filterMessages = () => {
      if (!searchTerm) {
        setFilteredMessages(messages);
      } else {
        const filtered = messages.filter(message =>
          message.id.includes(searchTerm)
        );
        setFilteredMessages(filtered);
      }
    };

    filterMessages();
  }, [searchTerm, messages]);

  const handleMessageClick = (messageId) => {
    navigate(`/dashboard/${workspaceId}/messages/${messageId}`);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const formatPhoneNumber = (phoneNumber) => {
    // Remove all non-digit characters
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    
    // Check if the input is of correct length
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    
    if (match) {
      // Format the phone number
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    
    // Return the original phone number if it's not in the expected format
    return phoneNumber;
  };

  return (
    <Box display="flex" height="100%">
      <VStack align="stretch" width="400px" height="100%" p={4} borderRight="1px" borderRightColor="gray.200">
        <Flex align="center">
          <FiMessageSquare size={30} style={{ marginRight: '12px' }} />
          <Heading as="h2" size="xl">
            Messages
          </Heading>
        </Flex>
        <Text fontSize="xl" fontWeight="bold" mb={2}>
          {filteredMessages.length} Messages
        </Text>
        <InputGroup size="lg">
          <InputLeftElement pointerEvents="none">
            <Icon as={SearchIcon} color="gray.400" />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search by phone number"
            bg="white"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
        <Box
          height="calc(100vh - 266px)"
          overflowY="scroll"
          css={{
            '&::-webkit-scrollbar': {
              width: '0px',
            },
            '&::-webkit-scrollbar-track': {
              width: '0px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'transparent',
            },
          }}
        >
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <Spinner />
            </Box>
          ) : (
            <VStack spacing={2} align="stretch">
              {filteredMessages.length > 0 ? (
                filteredMessages.map((message) => (
                  <Box
                    key={message.id}
                    p={2}
                    borderWidth="1px"
                    borderRadius="md"
                    onClick={() => handleMessageClick(message.id)}
                    cursor="pointer"
                    _hover={{ bg: 'gray.100' }}
                  >
                    <Flex direction="column" alignItems="start">
                      <HStack width="100%" justifyContent="space-between">
                        <Text fontSize="sm" color="gray.500">Phone: {formatPhoneNumber(message.id)}</Text>
                        {message.status === 'unread' && (
                          <Badge colorScheme="blue" borderRadius="full" px={2} py={1}>
                            Unread
                          </Badge>
                        )}
                      </HStack>
                      <Text fontSize="sm" color="gray.500">{formatDate(message.mostRecentTime)}</Text>
                      <Text fontWeight="bold">{message.sender}</Text>
                      <Text noOfLines={1}>{message.mostRecentMessage}</Text>
                    </Flex>
                  </Box>
                ))
              ) : (
                <Text>No messages found.</Text>
              )}
            </VStack>
          )}
        </Box>
      </VStack>
      <Box flex="1" p={4}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Messages;