// components/Agency.js
import React from 'react';
import { VStack, Heading, Button, Box } from '@chakra-ui/react';
import { Outlet, Link, useParams } from 'react-router-dom';

const Agency = () => {
  const { workspaceId } = useParams();

  return (
    <Box display="flex">
      <VStack
        as="nav"
        bg="gray.100"
        w="220px"
        p={4}
        spacing={4}
        align="start"
        position="sticky"
        top="0"
        height="calc(100vh - 81px)"
      >
        <Heading size="md">Agency</Heading>
        <Button as={Link} to={`/dashboard/${workspaceId}/agency/branding`} variant="link">Branding</Button>
        <Button as={Link} to={`/dashboard/${workspaceId}/agency/subaccounts`} variant="link">Subaccounts</Button>
        <Button as={Link} to={`/dashboard/${workspaceId}/agency/domain`} variant="link">Domain</Button>
        <Button as={Link} to={`/dashboard/${workspaceId}/agency/permissions`} variant="link">Permissions</Button>
      </VStack>
      <Box flex="1" p={4}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Agency;
