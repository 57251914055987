import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  Switch,
  Button,
  VStack,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { DeleteIcon, AddIcon } from "@chakra-ui/icons";

const FieldEditModal = ({ isOpen, onClose, field, onSave }) => {
  const [editedField, setEditedField] = useState(field || {});
  const [contactFieldType, setContactFieldType] = useState("custom");

  useEffect(() => {
    if (field) {
      setEditedField(field);
      const predefinedTypes = ["firstName", "middleName", "lastName", "phoneNumber", "companyName", "additionalInfo"];
      setContactFieldType(predefinedTypes.includes(field.contactField) ? field.contactField : "custom");
    }
  }, [field]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditedField((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleTypeChange = (e) => {
    const newType = e.target.value;
    setEditedField((prev) => ({
      ...prev,
      type: newType,
      options: newType.match(/multiple|select|radio/) ? (prev.options || []) : [],
      delayTimer: newType === 'phone' ? (prev.delayTimer || 0) : undefined,
    }));
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...editedField.options];
    newOptions[index] = value;
    setEditedField((prev) => ({ ...prev, options: newOptions }));
  };

  const handleAddOption = () => {
    setEditedField((prev) => ({
      ...prev,
      options: [...(prev.options || []), ""],
    }));
  };

  const handleDeleteOption = (index) => {
    const newOptions = [...editedField.options];
    newOptions.splice(index, 1);
    setEditedField((prev) => ({ ...prev, options: newOptions }));
  };

  const handleContactFieldTypeChange = (e) => {
    const newContactFieldType = e.target.value;
    setContactFieldType(newContactFieldType);
    setEditedField((prev) => ({
      ...prev,
      contactField: newContactFieldType === "custom" ? prev.customFieldName || "" : newContactFieldType,
    }));
  };

  const handleSave = () => {
    const fieldToSave = { ...editedField };

    fieldToSave.contactField = contactFieldType === "custom" ? editedField.customFieldName || "" : contactFieldType;

    delete fieldToSave.customFieldName;

    if (fieldToSave.type.match(/multiple|select|radio/)) {
      fieldToSave.options = fieldToSave.options.filter(option => option.trim() !== '');
      
      if (!Array.isArray(fieldToSave.options)) {
        fieldToSave.options = [];
      }
    } else {
      delete fieldToSave.options;
    }

    if (fieldToSave.type !== 'phone') {
      delete fieldToSave.delayTimer;
    }

    onSave(fieldToSave);
  };

  if (!field) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Field</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Label</FormLabel>
              <Input
                name="label"
                value={editedField.label || ""}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select name="type" value={editedField.type || "text"} onChange={handleTypeChange}>
                <option value="text">Text</option>
                <option value="textarea">Textarea</option>
                <option value="multiple">Multiple Choice Select</option>
                <option value="select">Single Select</option>
                <option value="checkbox">Checkbox</option>
                <option value="radio">Radio</option>
                <option value="phone">Phone Number</option>
              </Select>
            </FormControl>
            {editedField.type && editedField.type.match(/multiple|select|radio/) && (
              <FormControl>
                <FormLabel>Options</FormLabel>
                <VStack align="stretch">
                  {editedField.options && editedField.options.map((option, index) => (
                    <HStack key={index}>
                      <Input
                        value={option}
                        onChange={(e) => handleOptionChange(index, e.target.value)}
                        placeholder={`Option ${index + 1}`}
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        onClick={() => handleDeleteOption(index)}
                        aria-label="Delete option"
                      />
                    </HStack>
                  ))}
                  <Button leftIcon={<AddIcon />} onClick={handleAddOption}>
                    Add Option
                  </Button>
                </VStack>
              </FormControl>
            )}
            <FormControl>
              <FormLabel>Placeholder</FormLabel>
              <Input
                name="placeholder"
                value={editedField.placeholder || ""}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl display="flex" alignItems="center">
              <FormLabel mb="0">Required</FormLabel>
              <Switch
                name="required"
                isChecked={editedField.required || false}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Contact Field Type</FormLabel>
              <Select
                value={contactFieldType}
                onChange={handleContactFieldTypeChange}
              >
                <option value="firstName">First Name</option>
                <option value="middleName">Middle Name</option>
                <option value="lastName">Last Name</option>
                <option value="phoneNumber">Phone</option>
                <option value="companyName">Company</option>
                <option value="additionalInfo">Additional</option>
                <option value="custom">Custom</option>
              </Select>
            </FormControl>
            {contactFieldType === "custom" && (
              <FormControl>
                <FormLabel>Custom Field Name</FormLabel>
                <Input
                  name="customFieldName"
                  value={editedField.customFieldName || ""}
                  onChange={handleChange}
                  placeholder="Enter custom field name"
                />
              </FormControl>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FieldEditModal;