import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Grid,
  GridItem,
  VStack,
  Flex,
  IconButton,
  Text,
  Tooltip,
  useToast,
  Box,
  useDisclosure,
  HStack,
  Input,
} from "@chakra-ui/react";
import { CopyIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { db } from "../../../../Firebase";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import FormBuilder from "./FormBuilder";
import FormOptions from "./FormOptions";
import FieldEditModal from "./FieldEditModal";
import Buttons from "../Buttons";

const FormEdit = () => {
  const { workspaceId, agentId } = useParams();
  const { currentUser } = useAuth();
  const [formFields, setFormFields] = useState([]);
  const [formName, setFormName] = useState("");
  const [formOptions, setFormOptions] = useState({
    showTitle: true,
    bgColor: "rgba(255, 255, 255, 1)",
    buttonColor: "#007bff",
    borderRadius: 8,
    firstMessage: "",
    delayTimer: 1,
    callAfter: true,
    smsMessage: "",
  });
  const previewRef = useRef(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedField, setSelectedField] = useState(null);

  const fetchForm = useCallback(async () => {
    if (currentUser && workspaceId && agentId) {
      const agentDocRef = doc(db, "workspaces", workspaceId, "agents", agentId);
      onSnapshot(agentDocRef, (agentDoc) => {
        if (agentDoc.exists()) {
          const agentData = agentDoc.data();
          if (agentData.form) {
            setFormFields(agentData.form.fields || []);
            setFormName(agentData.form.name || "");
            setFormOptions({
              showTitle: agentData.form.showTitle ?? true,
              bgColor: agentData.form.bgColor ?? "rgba(255, 255, 255, 1)",
              buttonColor: agentData.form.buttonColor ?? "#007bff",
              borderRadius: agentData.form.borderRadius ?? 8,
              firstMessage: agentData.form.firstMessage || "",
              delayTimer: agentData.form.delayTimer || 1,
              callAfter: agentData.form.callAfter ?? true,
              smsMessage: agentData.form.smsMessage || "",
            });
          }
        }
      });
    }
  }, [currentUser, workspaceId, agentId]);

  const updateIframe = useCallback(() => {
    if (previewRef.current) {
      previewRef.current.srcdoc = `
        <div id="clientbase-form-${agentId}"></div>
        <script src="https://clientbaseserver.onrender.com/embed.js" data-workspace-id="${workspaceId}" data-agent-id="${agentId}"></script>
      `;
    }
  }, [workspaceId, agentId]);

  const saveForm = useCallback(async () => {
    if (currentUser && workspaceId && agentId) {
      const agentDocRef = doc(db, "workspaces", workspaceId, "agents", agentId);
      await updateDoc(agentDocRef, {
        form: {
          fields: formFields,
          name: formName,
          ...formOptions,
        },
      });
    }
  }, [formFields, formName, formOptions, currentUser, workspaceId, agentId]);

  useEffect(() => {
    fetchForm();
  }, [fetchForm]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      saveForm();
      updateIframe();
    }, 500);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [formFields, formName, formOptions, updateIframe, saveForm]);

  const handleCopyUrl = () => {
    const url = `https://clientbase.so/form/${workspaceId}/${agentId}`;
    navigator.clipboard.writeText(url);
    toast({
      title: "Successfully copied form URL!",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleGoToFormPage = () => {
    const url = `https://clientbase.so/form/${workspaceId}/${agentId}`;
    window.open(url, "_blank");
  };

  const handleEditField = (field, index) => {
    const fieldToEdit = { ...field, index };
    
    const predefinedTypes = ["firstName", "middleName", "lastName", "phone", "company", "additional"];
    if (predefinedTypes.includes(field.contactField)) {
      fieldToEdit.contactFieldType = field.contactField;
    } else {
      fieldToEdit.contactFieldType = "custom";
      fieldToEdit.customFieldName = field.contactField;
    }

    setSelectedField(fieldToEdit);
    onOpen();
  };

  const handleSaveField = (updatedField) => {
    const newFields = [...formFields];
    const fieldToSave = {
      label: updatedField.label,
      placeholder: updatedField.placeholder,
      required: updatedField.required,
      type: updatedField.type,
      contactField: updatedField.contactField,
    };

    if (updatedField.type.match(/multiple|select|radio/)) {
      fieldToSave.options = updatedField.options.filter(
        (option) => option.trim() !== ""
      );
    }

    if (updatedField.type === "phone") {
      fieldToSave.delayTimer = updatedField.delayTimer || 0;
    }

    newFields[updatedField.index] = fieldToSave;
    setFormFields(newFields);
    onClose();
  };

  return (
    <Grid templateColumns="50% 45%" gap={8} p={4}>
      <GridItem>
        <VStack align="stretch" spacing={4}>
          <Flex align="center">
            <Buttons workspaceId={workspaceId} agentId={agentId} />
          </Flex>
          <Box
            height="calc(100vh - 200px)"
            overflowY="auto"
            css={{
              "&::-webkit-scrollbar": {
                width: "0",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "transparent",
              },
            }}
          >
            <Input
              value={formName}
              onChange={(e) => setFormName(e.target.value)}
              placeholder="Form Name"
              size="lg"
              fontWeight="bold"
              mb={4}
            />
            <FormBuilder
              formFields={formFields}
              setFormFields={setFormFields}
              formOptions={formOptions}
              setFormOptions={setFormOptions}
              onEditField={handleEditField}
            />
            <FormOptions
              formOptions={formOptions}
              setFormOptions={setFormOptions}
              workspaceId={workspaceId}
              agentId={agentId}
            />
          </Box>
        </VStack>
      </GridItem>
      <GridItem>
        <Box
          bg="white"
          p={6}
          borderRadius="md"
          borderWidth="1px"
          borderColor="gray.200"
          position="sticky"
          top={8}
          >
            <Flex justify="space-between" align="center" mb={4}>
              <Text fontSize="xl" fontWeight="bold">
                Form Preview
              </Text>
              <HStack spacing={2}>
                <Tooltip label="Copy URL" fontSize="md">
                  <IconButton
                    icon={<CopyIcon boxSize={6} />}
                    variant="ghost"
                    onClick={handleCopyUrl}
                    aria-label="Copy URL"
                    mr={2}
                  />
                </Tooltip>
                <Tooltip label="Go to Form Page" fontSize="md">
                  <IconButton
                    icon={<ExternalLinkIcon boxSize={6} />}
                    variant="ghost"
                    onClick={handleGoToFormPage}
                    aria-label="Go to Form Page"
                    mr={2}
                  />
                </Tooltip>
              </HStack>
            </Flex>
            <Box
              as="iframe"
              ref={previewRef}
              width="100%"
              height="calc(100vh - 220px)"
              border="none"
            />
          </Box>
        </GridItem>
  
        {selectedField && (
          <FieldEditModal
            isOpen={isOpen}
            onClose={onClose}
            field={selectedField}
            onSave={handleSaveField}
          />
        )}
      </Grid>
    );
  };
  
  export default FormEdit;