import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
// import Home from './components/Home';
import Login from './components/Login';
import Signup from './components/Signup';
import Dashboard from './components/Dashboard';
import customTheme from './theme';
import Overview from './components/tabs/Overview';
import Settings from './components/tabs/Settings';
import { AuthProvider } from './components/AuthContext';
import HandleInvite from './components/utils/HandleInvite';
import ProtectedRoute from './components/utils/ProtectedRoute';

// Settings
import General from './components/tabs/settings/General';
import Members from './components/tabs/settings/Members';
import PlanBilling from './components/tabs/settings/PlanBilling';
import LoginCredentials from './components/tabs/settings/LoginCredentials';
import Notifications from './components/tabs/settings/Notifications';

// Voice Agents
import VoiceAgents from './components/tabs/VoiceAgents';
import Agents from './components/tabs/voiceagents/Agents';
import PhoneNumbers from './components/tabs/settings/PhoneNumbers';
import AgentDetails from './components/tabs/voiceagents/AgentDetails';

// Call Logs
import CallLogs from './components/tabs/CallLogs';

// Other
import Messages from './components/tabs/Messages';
import Contacts from './components/tabs/Contacts';
import Integrations from './components/tabs/Integrations';

// Agency
import Agency from './components/tabs/Agency';
import Branding from './components/tabs/agency/Branding';
// import EditAgent from './components/tabs/voiceagents/edit/EditAgent';
import Subaccounts from './components/tabs/agency/Subaccounts';
import Domain from './components/tabs/agency/Domain';
import Permissions from './components/tabs/agency/Permissions';

import Flow from './components/tabs/voiceagents/edit-agent/Flow';
import FormEdit from './components/tabs/voiceagents/forms/FormEdit';
import CallDetail from './components/tabs/callLogs/CallDetail';
import ContactDetail from './components/tabs/contacts/ContactDetails';
import Submissions from './components/tabs/voiceagents/forms/Submissions';
import Search from './components/widgets/Search';
import MessageDetails from './components/tabs/message/MessageDetails';

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Navigate to="/signup" replace />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/auth" element={<HandleInvite />} />
            <Route
              path="/dashboard/:workspaceId"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            >
              <Route index element={<Navigate to="overview" replace />} />
              <Route path="overview" element={<Overview />} />
              <Route path="search" element={<Search />} />
              <Route path="voice-agents" element={<VoiceAgents />} />
              <Route path="voice-agent/agents" element={<Agents />} />
              <Route path="voice-agent/agents/:agentId" element={<AgentDetails />} />
              <Route path="voice-agent/agents/:agentId/edit" element={<Flow />} />
              <Route path="voice-agent/agents/:agentId/form-edit" element={<FormEdit />} />
              <Route path="voice-agent/agents/:agentId/submissions" element={<Submissions />} />
              <Route path="call-logs" element={<CallLogs />}>
                <Route path=":callId" element={<CallDetail />} />
              </Route>
              <Route path="messages" element={<Messages />}>
                <Route path=":messageId" element={<MessageDetails />} />
              </Route>
              <Route path="contacts" element={<Contacts />} >
                <Route path=":contactId" element={<ContactDetail />} />
              </Route>

              <Route path="integrations" element={<Integrations />} />
              <Route path="agency" element={<Agency />} >
                <Route index element={<Navigate to="branding" replace />} />
                <Route path="branding" element={<Branding />} />
                <Route path="subaccounts" element={<Subaccounts />} />
                <Route path="domain" element={<Domain />} />
                <Route path="permissions" element={<Permissions />} />
              </Route>
              <Route path="settings" element={<Settings />}>
                <Route index element={<Navigate to="general" replace />} />
                <Route path="general" element={<General />} />
                <Route path="members" element={<Members />} />
                <Route path="plan-billing" element={<PlanBilling />} />
                <Route path="login-credentials" element={<LoginCredentials />} />
                <Route path="notifications" element={<Notifications />} />
                <Route path="phone-numbers" element={<PhoneNumbers />} />
              </Route>
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </ChakraProvider>
  );
}

export default App;
