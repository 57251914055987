import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  VStack,
  Text,
  Flex,
  Spinner,
  Button,
  Center,
  Heading,
} from "@chakra-ui/react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { db } from "../../../Firebase";

const CallLogsSearch = ({ currentUser }) => {
  const { workspaceId } = useParams();
  const [searchParams] = useSearchParams();
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(false);
  const [isPhoneNumberSearch, setIsPhoneNumberSearch] = useState(false);
  const navigate = useNavigate();

  const formatPhoneNumber = useCallback((phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    if (cleaned.length === 10) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(
        6
      )}`;
    } else if (cleaned.length === 11) {
      return `+${cleaned.slice(0, 1)} (${cleaned.slice(1, 4)}) ${cleaned.slice(
        4,
        7
      )}-${cleaned.slice(7)}`;
    }
    return phoneNumber; // Return original if not 10 or 11 digits
  }, []);

  const getOrdinalSuffix = useCallback((day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }, []);

  const formatDate = useCallback(
    (dateString) => {
      const date = new Date(dateString);
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

      return `${month} ${day}${getOrdinalSuffix(
        day
      )}, ${year} ${formattedHours}:${formattedMinutes} ${ampm}`;
    },
    [getOrdinalSuffix]
  );

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
      return;
    }

    const logsCollectionRef = collection(db, `workspaces/${workspaceId}/logs`);
    const q = query(
      logsCollectionRef,
      orderBy("message.call.createdAt", "desc")
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const logsList = snapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          rawNumber: data.message.call.customer.number.replace(/\D/g, ""),
          formattedNumber: formatPhoneNumber(data.message.call.customer.number),
          formattedDate: formatDate(data.message.call.createdAt),
        };
      });

      setLogs(logsList);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [workspaceId, currentUser, navigate, formatPhoneNumber, formatDate]);

  useEffect(() => {
    const searchTerm = searchParams.get("q") || "";

    if (searchTerm && searchTerm.trim() !== "") {
      setSearching(true);
      const timer = setTimeout(() => {
        // Check if the search term is a phone number (contains only digits after removing non-digits)
        const cleanedSearchTerm = searchTerm.replace(/\D/g, "");
        const isPhoneNumber =
          cleanedSearchTerm.length > 0 && /^\d+$/.test(cleanedSearchTerm);
        setIsPhoneNumberSearch(isPhoneNumber);

        if (isPhoneNumber) {
          const filtered = logs.filter((log) =>
            log.rawNumber.includes(cleanedSearchTerm)
          );
          setFilteredLogs(filtered);
        } else {
          // If it's not a phone number, don't show any logs
          setFilteredLogs([]);
        }
        setSearching(false);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      setFilteredLogs([]);
      setSearching(false);
      setIsPhoneNumberSearch(false);
    }
  }, [logs, searchParams]);

  const handleCallClick = (log) => {
    navigate(`/dashboard/${workspaceId}/call-logs/${log.id}`);
  };

  if (loading) {
    return (
      <Center height="200px">
        <Spinner />
      </Center>
    );
  }

  return (
    <Box borderWidth="1px" p="4" borderRadius="md">
      <Heading as="h2" size="lg" mb={4}>
        Call Logs
      </Heading>
      <Box
        height="calc(100vh - 260px)"
        overflowY="scroll"
        css={{
          "&::-webkit-scrollbar": {
            width: "0px",
          },
          "&::-webkit-scrollbar-track": {
            width: "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "transparent",
          },
        }}
      >
        {searching ? (
          <Center height="200px">
            <Spinner />
          </Center>
        ) : (
          <VStack align="stretch" spacing={2}>
            {!searchParams.get("q") || searchParams.get("q").trim() === "" ? (
              <Text>Please enter a search query</Text>
            ) : isPhoneNumberSearch ? (
              filteredLogs.length === 0 ? (
                <Text>No call logs found.</Text>
              ) : (
                filteredLogs.map((log) => (
                  <Button
                    key={log.id}
                    variant="outline"
                    width="400px"
                    onClick={() => handleCallClick(log)}
                  >
                    <Flex
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Text>{log.formattedNumber}</Text>
                      <Text fontSize="sm">{log.formattedDate}</Text>
                    </Flex>
                  </Button>
                ))
              )
            ) : (
              <Text>No call logs found.</Text>
            )}
          </VStack>
        )}
      </Box>
    </Box>
  );
};

export default CallLogsSearch;
