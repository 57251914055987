import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Box, IconButton, Text, Flex, Spacer, Input, Button, useToast } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNodeContext } from '../NodeContext'; // Make sure this path is correct
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../../../../Firebase'; // Adjust the import path as needed

const TestAgentSidebar = ({ isOpen, onClose }) => {
  const sidebarRef = useRef();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { workspaceId, agentId } = useParams();
  const toast = useToast();
  const { saveGraphData } = useNodeContext();

  const loadTestPhoneNumber = useCallback(async () => {
    const agentRef = doc(db, 'workspaces', workspaceId, 'agents', agentId);
    const agentSnap = await getDoc(agentRef);
    if (agentSnap.exists()) {
      const agentData = agentSnap.data();
      if (agentData.testPhoneNumber) {
        setPhoneNumber(agentData.testPhoneNumber);
      }
    }
  }, [workspaceId, agentId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      loadTestPhoneNumber();
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose, loadTestPhoneNumber]);

  const formatPhoneNumber = (number) => {
    if (number.length === 10) {
      return `+1${number}`;
    } else if (number.length > 10) {
      return `+${number}`;
    }
    return number;
  };

  const handleTestCall = async () => {
    const cleanedNumber = phoneNumber.replace(/\D/g, '');
    if (cleanedNumber.length < 10) {
      toast({
        title: "Error",
        description: "Please enter a valid phone number with at least 10 digits",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const formattedNumber = formatPhoneNumber(cleanedNumber);

    setIsLoading(true);

    try {
      await axios.post('https://clientbaseserver.onrender.com/test-my-agent', {
        workspaceId,
        agentId,
        phoneNumber: formattedNumber,
        type: 'test-agent',
        metadata: []
      });

      // Save the testPhoneNumber to the agent data
      const agentRef = doc(db, 'workspaces', workspaceId, 'agents', agentId);
      await updateDoc(agentRef, {
        testPhoneNumber: formattedNumber
      });

      // Update the local state
      setPhoneNumber(formattedNumber);

      // Notify the NodeContext that data has changed
      saveGraphData();

      toast({
        title: "Success",
        description: "Test call initiated successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error initiating test call:', error);
      toast({
        title: "Error",
        description: "Failed to initiate test call",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value.replace(/\D/g, '');
    let formattedInput = input;

    if (input.length > 6) {
      formattedInput = `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6, 10)}`;
    } else if (input.length > 3) {
      formattedInput = `(${input.slice(0, 3)}) ${input.slice(3, 6)}`;
    } else if (input.length > 0) {
      formattedInput = `(${input}`;
    }

    setPhoneNumber(formattedInput);
  };

  return (
    <Box
      ref={sidebarRef}
      position="fixed"
      right="0"
      width="500px"
      height="calc(100vh - 81px)"
      backgroundColor="white"
      p={4}
      zIndex={10}
      borderLeft="1px"
      borderLeftColor="gray.200"
      overflowY="auto"
    >
      <Flex alignItems="center">
        <Text fontSize="xl" color="gray.500" ml={2}>Test Call</Text>
        <Spacer />
        <IconButton icon={<CloseIcon />} onClick={onClose} />
      </Flex>
      <Box mt={4}>
        <Input
          placeholder="Enter your phone number... (We'll give you a call)"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          mb={4}
          border="2px solid #CBD5E0"
        />
        <Button
          colorScheme="blue"
          width="100%"
          onClick={handleTestCall}
          isLoading={isLoading}
        >
          Call Me to Test
        </Button>
      </Box>
    </Box>
  );
};

export default TestAgentSidebar;
