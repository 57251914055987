export const monthlyPricingPlan = [
  {
    name: "Business Lite",
    monthlyPriceId: "price_1Pml73ANnNx410iFB1ZJgn4A",
    unitPriceId: "price_1Pml7zANnNx410iFumYaFMVo",
    price: "$595/month",
    features: [
      "2,000 Minutes",
      "25¢ per extra minute",
      "2 Agents",
      "Custom Phone Numbers",
      "Custom Dashboard With Analytics",
      "Edit Anytime & Highly Customized",
      "Transfer Calls Easily",
      "Send Text Messages & Links",
      "Build Web Forms That Return Calls",
      "Commercial Use",
    ],
    totalMinutes: 2000,
  },
  {
    name: "Business Professional",
    monthlyPriceId: "price_1Pml7YANnNx410iFtjdlKnRZ",
    unitPriceId: "price_1Pml8IANnNx410iFSm59jFKL",
    price: "$1,295/month",
    features: [
      "6,000 Minutes",
      "25¢ per extra minute",
      "Unlimited Agents",
      "Custom Phone Numbers",
      "Custom Dashboard With Analytics",
      "Edit Anytime & Highly Customized",
      "Transfer Calls Easily",
      "Send Text Messages & Links",
      "Build Web Forms That Return Calls",
      "Commercial Use",
      "Early Access To New Features",
    ],
    totalMinutes: 6000,
  },
  {
    name: "Enterprise",
    price: "contact Us",
    features: [
      "Volume Discounts On Minutes",
      "Custom Agents",
      "Custom Phone Numbers",
      "Custom Dashboard With Analytics",
      "Edit Anytime & Highly Customized",
      "Transfer Calls Easily",
      "Send Text Messages & Links",
      "Build Web Forms That Return Calls",
      "Prompt Finetuning",
      "Early Access To New Features",
      "Personal Onboarding Assistance",
      "Hands On Support & Integration",
      "Enterprise SLA",
      "Commercial Use",
    ],
    contactUs: true,
  },
];

export const yearlyPricingPlan = [
  {
    name: "Business Lite",
    monthlyPriceId: "price_1Pml9AANnNx410iF2021eOLl",
    unitPriceId: "price_1PmlABANnNx410iFa2SJZ4cu",
    price: "$495/month",
    features: [
      "24,000 Minutes",
      "25¢ per extra minute",
      "2 Agents",
      "Custom Phone Numbers",
      "Custom Dashboard With Analytics",
      "Edit Anytime & Highly Customized",
      "Transfer Calls Easily",
      "Send Text Messages & Links",
      "Build Web Forms That Return Calls",
      "Commercial Use",
      "Early Access To New Features",
    ],
    totalMinutes: 24000,
  },
  {
    name: "Business Professional",
    monthlyPriceId: "price_1Pml9hANnNx410iFfuy253br",
    unitPriceId: "price_1PmlAPANnNx410iFpEv1xzqx",
    price: "$995/month",
    features: [
      "72,000 Minutes",
      "25¢ per extra minute",
      "Unlimited Agents",
      "Custom Phone Numbers",
      "Custom Dashboard With Analytics",
      "Edit Anytime & Highly Customized",
      "Transfer Calls Easily",
      "Send Text Messages & Links",
      "Build Web Forms That Return Calls",
      "Commercial Use",
      "Early Access To New Features",
    ],
    totalMinutes: 72000,
  },
  {
    name: "Enterprise",
    price: "contact Us",
    features: [
      "Volume Discounts On Minutes",
      "Custom Agents",
      "Custom Phone Numbers",
      "Custom Dashboard With Analytics",
      "Edit Anytime & Highly Customized",
      "Transfer Calls Easily",
      "Send Text Messages & Links",
      "Build Web Forms That Return Calls",
      "Prompt Finetuning",
      "Early Access To New Features",
      "Personal Onboarding Assistance",
      "Hands On Support & Integration",
      "Enterprise SLA",
      "Commercial Use",
    ],
    contactUs: true,
  },
];
