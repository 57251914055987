import React, { useEffect, useState } from 'react';
import {
  Button,
  Box,
  Heading,
  Text,
  Input,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spinner,
  Select,
  useToast,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../../Firebase';
import { doc, getDoc, updateDoc, deleteDoc, collection, query, where, getDocs } from 'firebase/firestore';
import Buttons from './Buttons';

const AgentDetails = () => {
  const { workspaceId, agentId } = useParams();
  const navigate = useNavigate();
  const [agent, setAgent] = useState(null);
  const [newName, setNewName] = useState('');
  const [phoneNumberSubscriptions, setPhoneNumberSubscriptions] = useState([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
  const [inbound, setInbound] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const [conflictPhoneNumber, setConflictPhoneNumber] = useState('');
  const [conflictingAgentId, setConflictingAgentId] = useState('');
  const toast = useToast();

  useEffect(() => {
    const fetchAgent = async () => {
      try {
        const agentDocRef = doc(db, `workspaces/${workspaceId}/agents`, agentId);
        const agentDoc = await getDoc(agentDocRef);
        if (agentDoc.exists()) {
          const agentData = agentDoc.data();
          setAgent(agentData);
          setNewName(agentData.name);
          setSelectedPhoneNumber(agentData.phoneNumber || '');
          setInbound(agentData.inbound || false);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching agent: ', error);
      }
    };

    const fetchPhoneNumberSubscriptions = async () => {
      try {
        const workspaceDocRef = doc(db, `workspaces/${workspaceId}`);
        const workspaceDoc = await getDoc(workspaceDocRef);
        if (workspaceDoc.exists()) {
          const workspaceData = workspaceDoc.data();
          setPhoneNumberSubscriptions(workspaceData.phoneNumberSubscriptions || []);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching phone number subscriptions: ', error);
      }
    };

    fetchAgent();
    fetchPhoneNumberSubscriptions();
  }, [workspaceId, agentId]);

  const updateAgentDetails = async (updatedData, showToast = true) => {
    try {
      const agentDocRef = doc(db, `workspaces/${workspaceId}/agents`, agentId);
      await updateDoc(agentDocRef, updatedData);
      setAgent((prev) => ({ ...prev, ...updatedData }));
      if (showToast) {
        toast({
          title: 'Agent updated.',
          description: 'The agent details have been updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error updating agent details: ', error);
      toast({
        title: 'Error',
        description: 'There was an error updating the agent details.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleNameChange = (e) => {
    const updatedName = e.target.value;
    setNewName(updatedName);
    updateAgentDetails({ name: updatedName }, false); // Don't show toast for name change
  };

  const checkPhoneNumberConflict = async (phoneNumber) => {
    try {
      const agentsCollectionRef = collection(db, `workspaces/${workspaceId}/agents`);
      const q = query(agentsCollectionRef, where('phoneNumber', '==', phoneNumber));
      const querySnapshot = await getDocs(q);

      let conflictAgentId = '';
      querySnapshot.forEach((doc) => {
        if (doc.id !== agentId) {
          conflictAgentId = doc.id;
        }
      });

      setConflictingAgentId(conflictAgentId);
      return !!conflictAgentId;
    } catch (error) {
      console.error('Error checking phone number conflict: ', error);
      return false;
    }
  };

  const handlePhoneNumberClick = async (phoneNumber) => {
    if (phoneNumber) {
      const hasConflict = await checkPhoneNumberConflict(phoneNumber);
      if (hasConflict) {
        setConflictPhoneNumber(phoneNumber); // Set the conflictPhoneNumber state
        onConfirmOpen();
        return;
      }
    }
    setSelectedPhoneNumber(phoneNumber);
    updateAgentDetails({ phoneNumber });
  };

  const handleConfirmPhoneNumber = async () => {
    try {
      const conflictAgentDocRef = doc(db, `workspaces/${workspaceId}/agents`, conflictingAgentId);
      await updateDoc(conflictAgentDocRef, { phoneNumber: '' });
      setSelectedPhoneNumber(conflictPhoneNumber);
      updateAgentDetails({ phoneNumber: conflictPhoneNumber });
      toast({
        title: 'Phone number updated.',
        description: 'The phone number has been reassigned successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating phone number: ', error);
      toast({
        title: 'Error',
        description: 'There was an error updating the phone number.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    onConfirmClose();
  };

  const handleInboundChange = (e) => {
    const updatedInbound = e.target.value === 'true';
    setInbound(updatedInbound);
    updateAgentDetails({ inbound: updatedInbound });
  };

  const handleStatusChange = (e) => {
    const updatedStatus = e.target.value;
    updateAgentDetails({ status: updatedStatus });
  };

  const deleteAgent = async () => {
    try {
      const agentDocRef = doc(db, `workspaces/${workspaceId}/agents`, agentId);
      await deleteDoc(agentDocRef);
      navigate(`/dashboard/${workspaceId}/voice-agents`);
    } catch (error) {
      console.error('Error deleting agent: ', error);
    }
  };

  if (!agent) {
    return (
      <Flex>
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box p={4} w="500px">
      <Flex align="center" mb={2}>
      <Buttons workspaceId={workspaceId} agentId={agentId} />
      </Flex>
      <Heading mb={4}>Agent Details</Heading>
      <Flex align="center" mb={4}>
        <Text><strong>Name:</strong></Text>
        <Input value={newName} onChange={handleNameChange} ml={2} flex={1} />
      </Flex>
      <Text><strong>Phone Number:</strong></Text>
      <Flex mb={4}>
        {phoneNumberSubscriptions
          .filter((subscription) => subscription.status !== 'canceled')
          .map((subscription, index) => (
            <Button
              key={index}
              onClick={() => handlePhoneNumberClick(subscription.phoneNumber)}
              colorScheme={selectedPhoneNumber === subscription.phoneNumber ? 'blue' : 'gray'}
              m={1}
            >
              {subscription.phoneNumber}
            </Button>
          ))}
        <Button
          onClick={() => handlePhoneNumberClick('')}
          colorScheme={selectedPhoneNumber === '' ? 'blue' : 'gray'}
          m={1}
        >
          None
        </Button>
      </Flex>
      <Text><strong>Inbound:</strong></Text>
      <Select value={inbound.toString()} onChange={handleInboundChange} mb={4}>
        <option value="false">No</option>
        <option value="true">Yes</option>
      </Select>
      <Text><strong>Status:</strong></Text>
      <Select value={agent.status} onChange={handleStatusChange} mb={4}>
        <option value="active">Active</option>
        <option value="inactive">Inactive</option>
      </Select>
      <Button
        colorScheme="red"
        size="lg"
        width="100%"
        mt={4}
        leftIcon={<DeleteIcon />}
        onClick={onOpen}
      >
        Delete Agent
      </Button>

      {/* Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete this agent? <br/><br/> This action cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={deleteAgent}>
              Delete
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Phone Number Conflict Modal */}
      <Modal isOpen={isConfirmOpen} onClose={onConfirmClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Phone Number Conflict</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Another agent in this workspace is already using this phone number. <br/><br/> Do you want to proceed and assign this number to the current agent?<br/><br/><strong>WARNING: This will remove the phone number from the other agent.</strong>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleConfirmPhoneNumber}>
              Confirm
            </Button>
            <Button variant="ghost" onClick={onConfirmClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AgentDetails;
