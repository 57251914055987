import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Text,
  Flex,
  Icon,
  Spacer,
  Textarea,
  FormLabel,
} from '@chakra-ui/react';
import { CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import { FaPlay } from 'react-icons/fa';
import { useNodeContext } from '../NodeContext';

const StartSidebar = ({ onClose }) => {
  const { selectedNode, setSelectedNode, nodes, setNodes, saveGraphData, edges, deleteNode } = useNodeContext();
  const [firstMessage, setFirstMessage] = useState('');

  useEffect(() => {
    if (selectedNode && selectedNode.type === 'startNode') {
      setFirstMessage(selectedNode.data.firstMessage || '');
    }
  }, [selectedNode]);

  const handleFirstMessageChange = (e) => {
    const updatedMessage = e.target.value;
    setFirstMessage(updatedMessage);

    // Update the node data locally
    const updatedNodes = nodes.map((node) =>
      node.id === selectedNode.id ? { ...node, data: { ...node.data, firstMessage: updatedMessage } } : node
    );
    setNodes(updatedNodes);
    setSelectedNode({ ...selectedNode, data: { ...selectedNode.data, firstMessage: updatedMessage } });

    // Save the updated nodes to Firebase
    saveGraphData(updatedNodes, edges);
  };

  const handleClose = () => {
    setSelectedNode(null);
    onClose();
  };

  const handleDelete = () => {
    deleteNode(selectedNode.id);
    handleClose();
  };

  return (
    <Box
      position="absolute"
      top="0"
      right="0"
      width="500px"
      height="100%"
      backgroundColor="white"
      p={4}
      zIndex={10}
      borderLeft="1px"
      borderLeftColor="gray.200"
    >
      {selectedNode && selectedNode.type === 'startNode' && (
        <Box>
          <Flex alignItems="center">
            <Icon as={FaPlay} w={6} h={6} color="#5eff7e" />
            <Text fontSize="xl" color="gray.500" ml={2}>
              GREETING NODE
            </Text>
            <Spacer />
            <IconButton mr={4} icon={<CloseIcon />} onClick={handleClose} />
            <IconButton icon={<DeleteIcon />} colorScheme="red" onClick={handleDelete} />
          </Flex>
          <Box mt={4}>
            <FormLabel>Greeting Message</FormLabel>
            <Textarea
              placeholder="Greeting Message"
              value={firstMessage}
              onChange={handleFirstMessageChange}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default StartSidebar;
