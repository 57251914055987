import { extendTheme } from '@chakra-ui/react';

// Custom theme
const customTheme = extendTheme({
  styles: {
    global: {
      // Global styles
      body: {
        fontFamily: 'Reddit Sans, sans-serif',
        color: 'gray.800',
        bg: 'white',
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold', // Default is 'semibold'
        textTransform: 'uppercase',
        borderRadius: 'xl', // Default is 'md'
      },
      sizes: {
        xl: {
          h: '56px',
          fontSize: 'lg',
          px: '32px',
        },
      },
      variants: {
        primary: {
          bg: 'teal.400',
          color: 'white',
          _hover: {
            bg: 'teal.500',
          },
        },
        secondary: {
          bg: 'gray.200',
          color: 'gray.800',
          _hover: {
            bg: 'gray.300',
          },
        },
      },
    },
    Heading: {
      baseStyle: {
        fontFamily: 'Reddit Sans, sans-serif',
      },
    },
  },
});

export default customTheme;
