import React, { useState } from 'react';
import {
  Box,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Grid,
  GridItem,
  Text,
  Flex,
  VStack,
  Icon,
} from '@chakra-ui/react';
import {
  FiHeadphones,
  FiHome,
  FiHeart,
  FiCoffee,
  FiTool,
  FiPenTool,
} from 'react-icons/fi';

// Import templates from JSON files
import customerSupportTemplates from './templates/customerSupport.json';
import hospitalityTemplates from './templates/hospitality.json';
import healthcareTemplates from './templates/healthcare.json';
import realEstateTemplates from './templates/realEstate.json';
import constructionTemplates from './templates/construction.json';
import customTemplates from './templates/custom.json';

// Combine templates into a single object
const templatesByCategory = {
  'Customer Support': customerSupportTemplates,
  Hospitality: hospitalityTemplates,
  Healthcare: healthcareTemplates,
  'Real Estate': realEstateTemplates,
  Construction: constructionTemplates,
  Custom: customTemplates,
};

const AddAgentModal = ({ isOpen, onClose, createAgent }) => {
  const [selectedCategory, setSelectedCategory] = useState('All');

  // Mapping categories to their respective icons
  const categoryIcons = {
    'Customer Support': FiHeadphones,
    Hospitality: FiCoffee,
    Healthcare: FiHeart,
    'Real Estate': FiHome,
    Construction: FiTool,
    Custom: FiPenTool,
  };

  // Extract category names from the templatesByCategory object
  const categories = ['All', ...Object.keys(templatesByCategory)];

  // Determine the templates to display based on the selected category
  const displayedTemplates =
    selectedCategory === 'All'
      ? Object.values(templatesByCategory).flat()
      : templatesByCategory[selectedCategory];

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent pb={4}>
        <ModalHeader borderBottomWidth="1px">Select a Template</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex height="60vh">
            {/* Left Sidebar for Categories */}
            <VStack
              spacing={4}
              align="start"
              borderRightWidth="1px"
              pr={4}
              width="200px"
              py={4}
            >
              {categories.map((category, index) => (
                <Flex
                  key={index}
                  direction="row"
                  align="center"
                  cursor="pointer"
                  onClick={() => setSelectedCategory(category)}
                  _hover={{ bg: 'gray.100' }}
                  borderWidth="1px"
                  bg={selectedCategory === category ? 'blue.50' : 'transparent'}
                  color={selectedCategory === category ? 'blue.500' : 'gray.600'}
                  p={2}
                  borderRadius="md"
                  transition="background-color 0.3s"
                  w="full"
                >
                  {category !== 'All' && (
                    <Icon as={categoryIcons[category]} w={5} h={5} mr={2} />
                  )}
                  <Text fontWeight="bold">{category}</Text>
                </Flex>
              ))}
            </VStack>

            {/* Right Side for Templates with Scroll */}
            <Box
              pl={4}
              w="900px"
              maxHeight="60vh"
              overflowY="auto" // Enable vertical scrolling
            >
              <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                {displayedTemplates.map((template, index) => (
                  <GridItem
                    key={index}
                    onClick={() => createAgent(template)}
                    cursor="pointer"
                  >
                    <Box
                      borderWidth="1px"
                      borderRadius="lg"
                      overflow="hidden"
                      p={4}
                      _hover={{ bg: 'gray.50', borderColor: 'blue.400' }}
                      transition="all 0.3s"
                      h="150px"
                      bg="white"
                      boxShadow="md"
                    >
                      <Heading size="md" mb={2}>
                        {template.emoji} {template.name}
                      </Heading>
                      <Text>{template.description}</Text>
                    </Box>
                  </GridItem>
                ))}
              </Grid>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddAgentModal;
