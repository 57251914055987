import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  Text,
  Spinner,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Heading,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../Firebase';

const ContactSearch = ({ workspaceId }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const searchContacts = async () => {
      if (!searchTerm.trim() || !workspaceId) return;

      setLoading(true);
      const contactsRef = collection(db, `workspaces/${workspaceId}/contacts`);
      const searchTermLower = searchTerm.toLowerCase();

      try {
        const q = query(
          contactsRef,
          where('searchTerms', 'array-contains', searchTermLower)
        );
        const querySnapshot = await getDocs(q);
        const contactsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setContacts(contactsData);
      } catch (error) {
        console.error('Error searching contacts:', error);
        setContacts([]);
      } finally {
        setLoading(false);
      }
    };

    const debounceTimer = setTimeout(searchContacts, 300);
    return () => clearTimeout(debounceTimer);
  }, [searchTerm, workspaceId]);

  return (
    <Box borderWidth="1px" p="4" borderRadius="md">
      <Heading as="h2" size="lg" mb={4}>
        Contact Search
      </Heading>
      <InputGroup size="lg" mb={4}>
        <InputLeftElement pointerEvents="none">
          <Icon as={SearchIcon} color="gray.400" />
        </InputLeftElement>
        <Input
          type="text"
          placeholder="Search contacts"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>
      <Box
        height="calc(100vh - 400px)"
        overflowY="scroll"
        css={{
          '&::-webkit-scrollbar': {
            width: '0px',
          },
          '&::-webkit-scrollbar-track': {
            width: '0px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'transparent',
          },
        }}
      >
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Spinner />
          </Box>
        ) : (
          <VStack spacing={2} align="stretch">
            {contacts.length > 0 ? (
              contacts.map((contact) => (
                <Box
                  key={contact.id}
                  p={2}
                  borderWidth="1px"
                  borderRadius="md"
                  cursor="pointer"
                  _hover={{ bg: 'gray.100' }}
                >
                  {contact.firstName || contact.middleName || contact.lastName ? (
                    <>
                      <Text fontWeight="bold">
                        {contact.firstName} {contact.middleName} {contact.lastName}
                      </Text>
                      <Text>{contact.phoneNumber}</Text>
                    </>
                  ) : (
                    <Text fontWeight="bold">{contact.phoneNumber}</Text>
                  )}
                  {contact.lastCallAt && (
                    <Text fontSize="sm">
                      Last Call At: {new Date(contact.lastCallAt.seconds * 1000).toLocaleString()}
                    </Text>
                  )}
                </Box>
              ))
            ) : (
              <Text>No contacts found.</Text>
            )}
          </VStack>
        )}
      </Box>
    </Box>
  );
};

export default ContactSearch;