import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Text,
  Flex,
  Icon,
  Spacer,
  Textarea,
  FormLabel,
  Input,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import { CloseIcon, DeleteIcon, InfoIcon } from '@chakra-ui/icons';
import { FaBrain } from 'react-icons/fa';
import { useNodeContext } from '../NodeContext';
import axios from 'axios';

const KnowledgeBaseSidebar = ({ onClose }) => {
  const { selectedNode, setSelectedNode, nodes, setNodes, saveGraphData, edges, deleteNode } = useNodeContext();
  const [intent, setIntent] = useState('');
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    if (selectedNode && selectedNode.type === 'knowledgeBaseNode') {
      setIntent(selectedNode.data.intent || '');
      setFiles(selectedNode.data.files || []);
    }
  }, [selectedNode]);

  const handleIntentChange = (e) => {
    const updatedIntent = e.target.value;
    setIntent(updatedIntent);

    const updatedNodes = nodes.map((node) =>
      node.id === selectedNode.id ? { ...node, data: { ...node.data, intent: updatedIntent } } : node
    );
    setNodes(updatedNodes);
    setSelectedNode({ ...selectedNode, data: { ...selectedNode.data, intent: updatedIntent } });
    saveGraphData(updatedNodes, edges);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) return;

    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post('https://api.vapi.ai/file', formData, {
        headers: {
          'Authorization': `Bearer 7ef675d9-946d-4e8d-9fbe-3aa3d40b1e06`,
          'Content-Type': 'multipart/form-data',
        },
      });

      const fileData = response.data;

      const updatedFiles = [...files, fileData];

      const updatedNodes = nodes.map((node) =>
        node.id === selectedNode.id ? { ...node, data: { ...node.data, files: updatedFiles } } : node
      );
      setNodes(updatedNodes);
      setSelectedNode({ ...selectedNode, data: { ...selectedNode.data, files: updatedFiles } });
      saveGraphData(updatedNodes, edges);

      toast({
        title: 'File uploaded successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setFile(null);
      onModalClose();
    } catch (error) {
      toast({
        title: 'File upload failed.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleFileClick = async (fileId) => {
    try {
      const response = await axios.get(`https://api.vapi.ai/file/${fileId}`, {
        headers: {
          'Authorization': `Bearer 7ef675d9-946d-4e8d-9fbe-3aa3d40b1e06`,
        },
      });
      setSelectedFile(response.data);
      onOpen();
    } catch (error) {
      toast({
        title: 'Failed to fetch file details.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleFileDelete = async () => {
    if (!selectedFile) return;

    try {
      await axios.delete(`https://api.vapi.ai/file/${selectedFile.id}`, {
        headers: {
          'Authorization': `Bearer 7ef675d9-946d-4e8d-9fbe-3aa3d40b1e06`,
        },
      });

      const updatedFiles = files.filter(file => file.id !== selectedFile.id);

      const updatedNodes = nodes.map((node) =>
        node.id === selectedNode.id ? { ...node, data: { ...node.data, files: updatedFiles } } : node
      );
      setNodes(updatedNodes);
      setSelectedNode({ ...selectedNode, data: { ...selectedNode.data, files: updatedFiles } });
      saveGraphData(updatedNodes, edges);

      toast({
        title: 'File deleted successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setSelectedFile(null);
      onModalClose();
    } catch (error) {
      toast({
        title: 'File deletion failed.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleClose = () => {
    setSelectedNode(null);
    onClose();
  };

  const handleDelete = () => {
    deleteNode(selectedNode.id);
    handleClose();
  };

  return (
    <Box
      position="absolute"
      top="0"
      right="0"
      width="500px"
      height="100%"
      backgroundColor="white"
      p={4}
      zIndex={10}
      borderLeft="1px"
      borderLeftColor="gray.200"
    >
      {selectedNode && selectedNode.type === 'knowledgeBaseNode' && (
        <Box>
          <Flex alignItems="center">
            <Icon as={FaBrain} w={6} h={6} color="#5eccff" />
            <Text fontSize="xl" color="gray.500" ml={2}>
              KNOWLEDGE BASE NODE
            </Text>
            <Spacer />
            <IconButton mr={4} icon={<CloseIcon />} onClick={handleClose} />
            <IconButton icon={<DeleteIcon />} colorScheme="red" onClick={handleDelete} />
          </Flex>
          <Box mt={4}>
            <FormLabel>Intent</FormLabel>
            <Textarea
              placeholder="Intent"
              value={intent}
              onChange={handleIntentChange}
            />
          </Box>
          <Box mt={4}>
            <Button onClick={onOpen}>Upload File</Button>
          </Box>
          <Box mt={4}>
            <Text fontSize="lg">Files:</Text>
            <List spacing={3}>
              {files.map((file) => (
                <ListItem key={file.id}>
                  <Flex alignItems="center">
                    <ListIcon as={InfoIcon} color="green.500" />
                    <Text cursor="pointer" onClick={() => handleFileClick(file.id)}>
                      {file.name}
                    </Text>
                  </Flex>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      )}

      <Modal isOpen={isOpen} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedFile ? 'File Details' : 'Upload Knowledge Base Item'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedFile ? (
              <Box>
                <Text><strong>File Name:</strong> {selectedFile.name}</Text>
                <Text><strong>Original Name:</strong> {selectedFile.originalName}</Text>
                <Text><strong>Size:</strong> {selectedFile.bytes} bytes</Text>
                <Text><strong>Type:</strong> {selectedFile.mimetype}</Text>
                <Text><strong>URL:</strong> <a href={selectedFile.url} target="_blank" rel="noopener noreferrer">{selectedFile.url}</a></Text>
                <Text><strong>Created At:</strong> {new Date(selectedFile.createdAt).toLocaleString()}</Text>
                <Text><strong>Updated At:</strong> {new Date(selectedFile.updatedAt).toLocaleString()}</Text>
              </Box>
            ) : (
              <Input type="file" onChange={handleFileChange} />
            )}
          </ModalBody>
          <ModalFooter>
            {selectedFile ? (
              <>
                <Button colorScheme="red" mr={3} onClick={handleFileDelete}>
                  Delete
                </Button>
                <Button variant="ghost" onClick={() => { setSelectedFile(null); onModalClose(); }}>Close</Button>
              </>
            ) : (
              <>
                <Button colorScheme="blue" mr={3} onClick={handleFileUpload}>
                  Upload
                </Button>
                <Button variant="ghost" onClick={onModalClose}>Cancel</Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default KnowledgeBaseSidebar;
