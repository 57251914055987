import React from "react";
import { VStack, HStack, Text, IconButton, Button } from "@chakra-ui/react";
import { FaGripVertical, FaEdit, FaTrash } from "react-icons/fa";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const DraggableField = ({ field, index, moveField, onEditField, onDeleteField }) => {
  const [, drag] = useDrag({
    type: "FIELD",
    item: { index },
  });

  const [, drop] = useDrop({
    accept: "FIELD",
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveField(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <HStack
      ref={(node) => drag(drop(node))}
      p={2}
      borderWidth="1px"
      borderRadius="md"
      bg="white"
      width="100%"
      mb={2}
    >
      <IconButton
        icon={<FaGripVertical />}
        variant="ghost"
        cursor="grab"
        aria-label="Drag to reorder"
      />
      <Text flex={1}>{field.label}</Text>
      <IconButton
        icon={<FaEdit />}
        variant="ghost"
        onClick={() => onEditField(field, index)}
        aria-label="Edit field"
      />
      <IconButton
        icon={<FaTrash />}
        variant="ghost"
        onClick={() => onDeleteField(index)}
        aria-label="Delete field"
      />
    </HStack>
  );
};

const FormBuilder = ({ formFields, setFormFields, onEditField }) => {
  const addField = () => {
    const newField = {
      label: `Field ${formFields.length + 1}`,
      type: "text",
      placeholder: "",
      required: false,
    };
    setFormFields([...formFields, newField]);
  };

  const moveField = (fromIndex, toIndex) => {
    const updatedFields = [...formFields];
    const [movedField] = updatedFields.splice(fromIndex, 1);
    updatedFields.splice(toIndex, 0, movedField);
    setFormFields(updatedFields);
  };

  const deleteField = (index) => {
    const updatedFields = formFields.filter((_, i) => i !== index);
    setFormFields(updatedFields);
  };

  return (
    <VStack spacing={4} align="stretch">
      <DndProvider backend={HTML5Backend}>
        <VStack spacing={1} align="stretch">
          {formFields.map((field, index) => (
            <DraggableField
              key={index}
              field={field}
              index={index}
              moveField={moveField}
              onEditField={onEditField}
              onDeleteField={deleteField}
            />
          ))}
        </VStack>
      </DndProvider>
      <Button colorScheme="blue" mt={-2} mb={4} onClick={addField}>
        Add Field
      </Button>
    </VStack>
  );
};

export default FormBuilder;