import React from 'react';
import { Box, Icon } from '@chakra-ui/react';
import { FaCog } from 'react-icons/fa';
import { useNodeContext } from '../NodeContext';

const SettingsNode = ({ id, data }) => {
  const { selectedNode, setSelectedNode } = useNodeContext();

  const handleClick = () => {
    setSelectedNode({ id, type: 'settingsNode', label: data.label });
  };

  return (
    <Box
      onClick={handleClick}
      style={{
        backgroundColor: 'white',
        padding: '16px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        fontWeight: 'bold',
        color: '#222222',
        border: `2px solid ${selectedNode && selectedNode.id === id ? '#3182CE' : '#CBD5E0'}`,
        borderRadius: '10px',
        position: 'relative',
      }}
    >
      <Box display="flex" alignItems="center">
        <Icon as={FaCog} w={6} h={6} color="#787878" />
        <span style={{ marginLeft: '8px' }}>Settings</span>
      </Box>
    </Box>
  );
};

export default SettingsNode;
