import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Text,
  Flex,
  Icon,
  Spacer,
  Textarea,
  FormLabel,
} from '@chakra-ui/react';
import { CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import { FaEnvelope } from 'react-icons/fa';
import { useNodeContext } from '../NodeContext';

const MessagesSidebar = ({ onClose }) => {
  const { selectedNode, setSelectedNode, nodes, setNodes, saveGraphData, edges, deleteNode } = useNodeContext();
  const [intent, setIntent] = useState('');
  const [smsMessage, setSmsMessage] = useState('');

  useEffect(() => {
    if (selectedNode && selectedNode.type === 'messagesNode') {
      setIntent(selectedNode.data.intent || '');
      setSmsMessage(selectedNode.data.smsMessage || '');
    }
  }, [selectedNode]);

  const handleIntentChange = (e) => {
    const updatedIntent = e.target.value;
    setIntent(updatedIntent);

    // Update the node data locally
    const updatedNodes = nodes.map((node) =>
      node.id === selectedNode.id ? { ...node, data: { ...node.data, intent: updatedIntent } } : node
    );
    setNodes(updatedNodes);
    setSelectedNode({ ...selectedNode, data: { ...selectedNode.data, intent: updatedIntent } });

    // Save the updated nodes to Firebase
    saveGraphData(updatedNodes, edges);
  };

  const handleSmsMessageChange = (e) => {
    const updatedMessage = e.target.value;
    setSmsMessage(updatedMessage);

    // Update the node data locally
    const updatedNodes = nodes.map((node) =>
      node.id === selectedNode.id ? { ...node, data: { ...node.data, smsMessage: updatedMessage } } : node
    );
    setNodes(updatedNodes);
    setSelectedNode({ ...selectedNode, data: { ...selectedNode.data, smsMessage: updatedMessage } });

    // Save the updated nodes to Firebase
    saveGraphData(updatedNodes, edges);
  };

  const handleClose = () => {
    setSelectedNode(null);
    onClose();
  };

  const handleDelete = () => {
    deleteNode(selectedNode.id);
    handleClose();
  };

  return (
    <Box
      position="absolute"
      top="0"
      right="0"
      width="500px"
      height="100%"
      backgroundColor="white"
      p={4}
      zIndex={10}
      borderLeft="1px"
      borderLeftColor="gray.200"
    >
      {selectedNode && selectedNode.type === 'messagesNode' && (
        <Box>
          <Flex alignItems="center">
            <Icon as={FaEnvelope} w={6} h={6} color="#6B46C1" />
            <Text fontSize="xl" color="gray.500" ml={2}>
              MESSAGES NODE
            </Text>
            <Spacer />
            <IconButton mr={4} icon={<CloseIcon />} onClick={handleClose} />
            <IconButton icon={<DeleteIcon />} colorScheme="red" onClick={handleDelete} />
          </Flex>
          <Box mt={4}>
            <FormLabel>Intent</FormLabel>
            <Textarea
              placeholder="What is the intent of this message."
              value={intent}
              onChange={handleIntentChange}
            />
          </Box>
          <Box mt={4}>
            <FormLabel>SMS Message</FormLabel>
            <Textarea
              placeholder="Enter the SMS message."
              value={smsMessage}
              onChange={handleSmsMessageChange}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MessagesSidebar;
