import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Text,
  Flex,
  Icon,
  Spacer,
  Textarea,
  FormLabel,
} from '@chakra-ui/react';
import { CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import { FaPhoneSlash } from 'react-icons/fa';
import { useNodeContext } from '../NodeContext';

const EndSidebar = ({ onClose }) => {
  const { selectedNode, setSelectedNode, nodes, setNodes, saveGraphData, edges, deleteNode } = useNodeContext();
  const [endCallPhrase, setEndCallPhrase] = useState('');

  useEffect(() => {
    if (selectedNode && selectedNode.type === 'endNode') {
      setEndCallPhrase(selectedNode.data.endCallPhrase || '');
    }
  }, [selectedNode]);

  const handleEndCallPhraseChange = (e) => {
    const updatedPhrase = e.target.value;
    setEndCallPhrase(updatedPhrase);

    // Update the node data locally
    const updatedNodes = nodes.map((node) =>
      node.id === selectedNode.id ? { ...node, data: { ...node.data, endCallPhrase: updatedPhrase } } : node
    );
    setNodes(updatedNodes);
    setSelectedNode({ ...selectedNode, data: { ...selectedNode.data, endCallPhrase: updatedPhrase } });

    // Save the updated nodes to Firebase
    saveGraphData(updatedNodes, edges);
  };

  const handleClose = () => {
    setSelectedNode(null);
    onClose();
  };

  const handleDelete = () => {
    deleteNode(selectedNode.id);
    handleClose();
  };

  return (
    <Box
      position="absolute"
      top="0"
      right="0"
      width="500px"
      height="100%"
      backgroundColor="white"
      p={4}
      zIndex={10}
      borderLeft="1px"
      borderLeftColor="gray.200"
    >
      {selectedNode && selectedNode.type === 'endNode' && (
        <Box>
          <Flex alignItems="center">
            <Icon as={FaPhoneSlash} w={6} h={6} color="#db420f" />
            <Text fontSize="xl" color="gray.500" ml={2}>
              END CALL NODE
            </Text>
            <Spacer />
            <IconButton mr={4} icon={<CloseIcon />} onClick={handleClose} />
            <IconButton icon={<DeleteIcon />} colorScheme="red" onClick={handleDelete} />
          </Flex>
          <Box mt={4}>
            <FormLabel>End Call Phrase</FormLabel>
            <Textarea
              placeholder="Enter the end call phrase. (When this is said in the chat the call will end)"
              value={endCallPhrase}
              onChange={handleEndCallPhraseChange}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EndSidebar;
