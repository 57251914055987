import React, { useState } from "react";
import {
  Box,
  Heading,
  Input,
  VStack,
  Grid,
  GridItem,
  Image,
  Text,
  Tag,
  useColorModeValue,
  Flex,
  Button,
} from "@chakra-ui/react";
import ShopifyLogo from "../../assets/shopify.png";
import CalLogo from "../../assets/cal.png";
import ZapierLogo from "../../assets/zapier.png";
import SalesforceLogo from "../../assets/salesforce.png";
import HubSpotLogo from "../../assets/hubspot.png";
import GHLLogo from "../../assets/ghl.png";
import { FiCloud } from "react-icons/fi";

const integrationOptions = [
  {
    name: "GoHighLevel",
    logo: GHLLogo,
    description: "Your all-in-one suite for sales and marketing.",
    comingSoon: false,
    connectUrl: "https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=https://app.clientbaseai.com/dashbord&client_id=66dfaf2884a0ffc9a8cef29a-m0vtlttf&scope=businesses.readonly businesses.write calendars.readonly calendars.write calendars/events.readonly calendars/events.write calendars/groups.readonly calendars/groups.write calendars/resources.readonly calendars/resources.write users.readonly users.write contacts.readonly contacts.write conversations.readonly conversations.write conversations/message.readonly conversations/message.write conversations/reports.readonly&loginWindowOpenMode=self"
  },
  { name: "Shopify", logo: ShopifyLogo, description: "E-commerce platform for online stores and retail point-of-sale systems.", comingSoon: true },
  { name: "Cal.com", logo: CalLogo, description: "Scheduling platform for managing appointments and events.", comingSoon: true },
  { name: "Zapier", logo: ZapierLogo, description: "Automation tool to connect your apps and automate workflows.", comingSoon: true },
  { name: "Salesforce", logo: SalesforceLogo, description: "Customer relationship management (CRM) platform.", comingSoon: true },
  { name: "HubSpot", logo: HubSpotLogo, description: "Inbound marketing, sales, and service software.", comingSoon: true },
];

const Integrations = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const bgColor = useColorModeValue("white", "gray.800");

  const filteredIntegrations = integrationOptions.filter(integration =>
    integration.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box p={4} w="calc(100vw - 300px)">
      <Flex alignItems="center" mb={6}>
        <FiCloud size={30} style={{ marginRight: '12px' }} />
        <Heading as="h2" size="xl">
          Integrations
        </Heading>
      </Flex>
      <Input
        placeholder="Search integrations..."
        mb={6}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <VStack align="stretch" spacing={4}>
        <Grid templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)", lg: "repeat(4, 1fr)", xl: "repeat(5, 1fr)" }} gap={6}>
          {filteredIntegrations.map((integration, index) => (
            <GridItem key={index}>
              <Box
                borderWidth={1}
                borderRadius="md"
                overflow="hidden"
                bg={bgColor}
                p={4}
                border="1px"
                borderColor="gray.200"
                minH={{ base: "auto", md: 150 }}
              >
                <Grid templateColumns="100px 1fr" gap={4} alignItems="center">
                  <GridItem>
                    <Image
                      src={integration.logo}
                      alt={integration.name}
                      boxSize={{ base: "40px", md: "60px", lg: "80px" }}
                      objectFit="contain"
                      mx="auto"
                    />
                  </GridItem>
                  <GridItem>
                    <Text fontSize="lg" fontWeight="bold">{integration.name}</Text>
                    <Text fontSize="sm">{integration.description}</Text>
                    {integration.comingSoon && (
                      <Tag colorScheme="blue" mt={2} borderRadius="full" size="sm">
                        COMING SOON
                      </Tag>
                    )}
                    {!integration.comingSoon && integration.connectUrl && (
                      <Button
                        mt={2}
                        colorScheme="blue"
                        size="sm"
                        onClick={() => window.open(integration.connectUrl, "_blank")}
                      >
                        Connect
                      </Button>
                    )}
                  </GridItem>
                </Grid>
              </Box>
            </GridItem>
          ))}
        </Grid>
      </VStack>
    </Box>
  );
};

export default Integrations;
