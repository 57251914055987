import React, { useState, useEffect } from 'react';
import { Box, Heading, Table, Thead, Tbody, Tr, Th, Td, Button } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../../Firebase';
import { collection, onSnapshot, addDoc } from 'firebase/firestore';

const Agents = () => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    const agentsCollectionRef = collection(db, `workspaces/${workspaceId}/agents`);
    const unsubscribe = onSnapshot(agentsCollectionRef, (snapshot) => {
      const agentsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAgents(agentsList);
    });

    return () => unsubscribe();
  }, [workspaceId]);

  const createAgent = async () => {
    try {
      const agentsCollectionRef = collection(db, `workspaces/${workspaceId}/agents`);
      const newAgentDoc = await addDoc(agentsCollectionRef, {
        name: 'New Agent',
        status: 'Active',
        phoneNumber: '',
        isInbound: false,
        form: {
          name: 'Agent Form',
          fields: [
            { label: 'Name', type: 'text', placeholder: 'Enter your name', required: true },
            { label: 'Phone Number', type: 'phone', placeholder: 'Enter your phone number', required: true },
          ],
          showTitle: true,
          bgColor: 'rgba(255, 255, 255, 1)',
          buttonColor: '#007bff',
          borderRadius: 8,
        },
      });

      navigate(`/dashboard/${workspaceId}/voice-agent/agents/${newAgentDoc.id}`);
    } catch (error) {
      console.error('Error creating agent: ', error);
    }
  };

  return (
    <Box p={4}>
      <Heading mb={4}>Agents</Heading>
      <Button onClick={createAgent} mb={4}>
        Create Agent
      </Button>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Status</Th>
            <Th>Phone Number</Th>
            <Th>Is Inbound</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {agents.map(agent => (
            <Tr key={agent.id}>
              <Td>{agent.name}</Td>
              <Td>{agent.status}</Td>
              <Td>{agent.phoneNumber || 'N/A'}</Td>
              <Td>{agent.isInbound ? 'Yes' : 'No'}</Td>
              <Td>
                <Button size="sm" colorScheme="teal" onClick={() => navigate(`/dashboard/${workspaceId}/voice-agent/agents/${agent.id}/edit`)}>
                  View
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default Agents;
