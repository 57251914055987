import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Heading,
  Button,
  SimpleGrid,
  Text,
  Badge,
  Avatar,
  Flex,
  IconButton,
  Center,
  useToast,
  HStack,
  Tooltip,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../Firebase';
import { collection, addDoc, onSnapshot, serverTimestamp, doc, getDoc } from 'firebase/firestore';
import { FiPlus, FiEye, FiPhone, FiPhoneIncoming } from 'react-icons/fi';
import AddAgentModal from './voiceagents/AddAgentModal'; // Import the new component
import { useDisclosure } from '@chakra-ui/react';

const VoiceAgents = () => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [agents, setAgents] = useState([]);
  const [planName, setPlanName] = useState('');

  const pricingPlansList = useMemo(
    () => [
      {
        name: 'Lite Plan',
        priceIds: ['price_1PiMYtANnNx410iFlQXWNwC0', 'price_1PiQTQANnNx410iF54xprJtj'],
      },
      {
        name: 'Professional Plan',
        priceIds: ['price_1PiMZpANnNx410iFOAvHfyP9', 'price_1PiQTjANnNx410iF5438zF1s'],
      },
      {
        name: 'Free',
        priceIds: ['Free'],
      },
    ],
    []
  );

  useEffect(() => {
    const agentsCollectionRef = collection(db, `workspaces/${workspaceId}/agents`);
    const unsubscribe = onSnapshot(agentsCollectionRef, (snapshot) => {
      const agentsList = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setAgents(agentsList);
    });

    const fetchWorkspaceData = async () => {
      const workspaceRef = doc(db, `workspaces/${workspaceId}`);
      const workspaceDoc = await getDoc(workspaceRef);
      if (workspaceDoc.exists()) {
        const workspaceData = workspaceDoc.data();
        const matchingPlan = pricingPlansList.find((plan) =>
          plan.priceIds.some((priceId) => workspaceData.planPriceIds.includes(priceId))
        );
        setPlanName(matchingPlan ? matchingPlan.name : 'Unknown Plan');
      }
    };

    fetchWorkspaceData();

    return () => unsubscribe();
  }, [workspaceId, pricingPlansList]);

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return 'None';
    const match = phoneNumber.match(/^(\+1)(\d{3})(\d{3})(\d{4})$/);
    return match ? `${match[1]} (${match[2]}) ${match[3]}-${match[4]}` : phoneNumber;
  };

  const createAgent = async (template) => {
    try {
      if ((planName === 'Free' && agents.length >= 1) || (planName === 'Lite Plan' && agents.length >= 2)) {
        toast({
          title: 'Upgrade Required',
          description: 'Please upgrade your plan to create another agent.',
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
        navigate(`/dashboard/${workspaceId}/settings/plan-billing`);
        return;
      }

      const agentsCollectionRef = collection(db, `workspaces/${workspaceId}/agents`);
      const newAgentDoc = await addDoc(agentsCollectionRef, {
        name: template.name,
        description: template.description,
        status: 'Active',
        inbound: false,
        phoneNumber: '',
        createdAt: serverTimestamp(),
        form: template.form,
        graph: template.graph,
      });
      navigate(`/dashboard/${workspaceId}/voice-agent/agents/${newAgentDoc.id}/edit`);
    } catch (error) {
      console.error('Error creating agent: ', error);
    }
  };

  return (
    <Box p={4} height="calc(100vh - 90px)">
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <Flex alignItems="center">
          <FiPhone size={30} style={{ marginRight: '12px' }} />
          <Heading>Voice Agents</Heading>
        </Flex>
        <Button leftIcon={<FiPlus />} colorScheme="blue" onClick={onOpen}>
          Create Agent
        </Button>
      </Flex>
      {agents.length === 0 ? (
        <Center height="50vh">
          <Text color="gray.500" fontSize="xl">
            You have no agents. Please create one.
          </Text>
        </Center>
      ) : (
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={6}>
          {agents.map((agent) => (
            <Box
              key={agent.id}
              borderColor="gray.200"
              borderWidth="1px"
              borderRadius="10px"
              cursor="pointer"
              _hover={{ bg: 'gray.100' }}
              onClick={() => navigate(`/dashboard/${workspaceId}/voice-agent/agents/${agent.id}/edit`)}
              p={4}
            >
              <Flex justifyContent="space-between" alignItems="center" mb={4}>
                <Flex alignItems="center">
                  <Avatar name={agent.name} src={agent.avatar} size="sm" mr={2} color="white" />
                  <Heading size="md">{agent.name}</Heading>
                </Flex>
                <Badge colorScheme={agent.status === 'active' ? 'green' : 'red'}>
                  {agent.status}
                </Badge>
              </Flex>
              <Text mb={4}>{agent.description}</Text>
              <Box>
                <Tooltip label="Phone Number" aria-label="Phone Number Tooltip">
                  <Flex alignItems="center" mb={2}>
                    <HStack borderWidth="1px" borderRadius="md" p={2} borderColor="gray.200">
                      <FiPhone style={{ marginRight: '10px' }} />
                      <Text>{formatPhoneNumber(agent.phoneNumber)}</Text>
                    </HStack>
                  </Flex>
                </Tooltip>
                <Tooltip label="Inbound Call Capability" aria-label="Inbound Tooltip">
                  <Flex alignItems="center">
                    <HStack borderWidth="1px" borderRadius="md" p={2} borderColor="gray.200">
                      <FiPhoneIncoming style={{ marginRight: '8px' }} />
                      <Text>Inbound:</Text>
                      <Badge colorScheme={agent.inbound ? 'green' : 'red'}>
                        {agent.inbound ? 'Yes' : 'No'}
                      </Badge>
                    </HStack>
                  </Flex>
                </Tooltip>
              </Box>
              <Flex justifyContent="flex-end">
                <IconButton
                  icon={<FiEye />}
                  aria-label="View Agent"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/dashboard/${workspaceId}/voice-agent/agents/${agent.id}`);
                  }}
                />
              </Flex>
            </Box>
          ))}
        </SimpleGrid>
      )}

      {/* Use the AddAgentModal component here */}
      <AddAgentModal isOpen={isOpen} onClose={onClose} createAgent={createAgent} />
    </Box>
  );
};

export default VoiceAgents;
