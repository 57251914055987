import React, { useState, useEffect, useCallback } from 'react';
import { Heading, Input, Button, Box, useToast, FormLabel } from '@chakra-ui/react';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';

const General = () => {
  const { workspaceId } = useParams();
  const [workspaceName, setWorkspaceName] = useState('');
  const [loading, setLoading] = useState(true);
  const db = getFirestore();
  const toast = useToast();

  const fetchWorkspaceData = useCallback(async () => {
    try {
      const workspaceRef = doc(db, 'workspaces', workspaceId);
      const workspaceSnap = await getDoc(workspaceRef);
      if (workspaceSnap.exists()) {
        setWorkspaceName(workspaceSnap.data().name);
      } else {
        console.error("No such document!");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching workspace data:", error);
      setLoading(false);
    }
  }, [db, workspaceId]);

  useEffect(() => {
    fetchWorkspaceData();
  }, [fetchWorkspaceData]);

  const handleSave = async () => {
    setLoading(true);
    try {
      const workspaceRef = doc(db, 'workspaces', workspaceId);
      await updateDoc(workspaceRef, { name: workspaceName });
      window.location.reload(); // Refresh the page
    } catch (error) {
      console.error("Error updating workspace name:", error);
      toast({
        title: "Error.",
        description: "There was an error updating the workspace name.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <Box maxW="500px" textAlign="left">
      <Heading mb={4}>General Settings</Heading>
      <FormLabel>Workspace ID</FormLabel>
      <Input
        placeholder="Workspace ID"
        value={workspaceId}
        isReadOnly
        mb={2}
        width="500px"
        bg="gray.100"
        color="gray.500"
      />
      <FormLabel>Workspace Name</FormLabel>
      <Input
        placeholder="Workspace Name"
        value={workspaceName}
        onChange={(e) => setWorkspaceName(e.target.value)}
        mb={4}
        width="500px"
      />
      <Button colorScheme="blue" onClick={handleSave} isLoading={loading} width="500px">
        Save
      </Button>
    </Box>
  );
};

export default General;
