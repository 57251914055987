import React, { useState, useEffect } from "react";
import {
  Heading,
  Input,
  Button,
  Box,
  VStack,
  HStack,
  Table,
  Tbody,
  Tr,
  Td,
  Select,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import axios from "axios";
import { getFunctions, httpsCallable } from "firebase/functions";
import { loadStripe } from "@stripe/stripe-js";
import PhoneNumbersDisplay from "./PhoneNumbersDisplay";

const stripePromise = loadStripe("pk_live_51PNpfIANnNx410iFRVNu3PhfHVjb8IsLv4Jqtx5xUSsljLWPnQU0uwVXvoRBqSNnMt4gUaVFYG3kQaEf985n30TO00h3ucm36f");

const countries = [
  { code: "US", name: "United States" },
  { code: "CA", name: "Canada" },
];

const PhoneNumbers = () => {
  const { workspaceId } = useParams();
  const [availableNumbers, setAvailableNumbers] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("US");
  const [areaCode, setAreaCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState({});
  const db = getFirestore();
  const functions = getFunctions();

  useEffect(() => {
    const fetchWorkspaceData = async () => {
      try {
        const workspaceRef = doc(db, "workspaces", workspaceId);
        const workspaceSnap = await getDoc(workspaceRef);
        if (!workspaceSnap.exists()) {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching workspace data:", error);
      }
    };

    fetchWorkspaceData();
    fetchAvailableNumbers();
  }, [workspaceId, db]);

  const fetchAvailableNumbers = async (countryCode = "US", areaCode = "") => {
    setLoading(true);
    const TWILIO_ACCOUNT_SID = process.env.REACT_APP_TWILIO_ACCOUNT_SID;
    const TWILIO_AUTH_TOKEN = process.env.REACT_APP_TWILIO_AUTH_TOKEN;
    const auth = btoa(`${TWILIO_ACCOUNT_SID}:${TWILIO_AUTH_TOKEN}`);

    try {
      const response = await axios.get(
        `https://api.twilio.com/2010-04-01/Accounts/${TWILIO_ACCOUNT_SID}/AvailablePhoneNumbers/${countryCode}/Local.json`,
        {
          headers: {
            Authorization: `Basic ${auth}`,
          },
          params: {
            AreaCode: areaCode,
            PageSize: 5,
          },
        }
      );
      setAvailableNumbers(response.data.available_phone_numbers);
      setLoading(false);
    } catch (error) {
      console.error("Error searching for phone numbers:", error);
      setLoading(false);
    }
  };

  const handleCheckout = async (phoneNumber) => {
    setIsCheckoutLoading(prev => ({ ...prev, [phoneNumber]: true }));
    const createPhoneNumberCheckout = httpsCallable(functions, "createPhoneNumberCheckout");

    try {
      const { data } = await createPhoneNumberCheckout({
        phoneNumber: phoneNumber,
        workspaceId,
        baseUrl: window.location.origin,
      });

      if (data.sessionId) {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: data.sessionId,
        });

        if (error) {
          console.error("Error redirecting to Stripe checkout:", error);
        }
      }
    } catch (error) {
      console.error("Error during checkout:", error);
    } finally {
      setIsCheckoutLoading(prev => ({ ...prev, [phoneNumber]: false }));
    }
  };

  return (
    <Box maxW="500px" textAlign="left">
      <Heading mb={4}>Phone Numbers</Heading>
      <PhoneNumbersDisplay />
      <Heading my={4} size="md">
        Purchase New Number
      </Heading>
      <VStack spacing={4}>
        <HStack width="100%" spacing={4}>
          <Input
            placeholder="Enter Area Code"
            value={areaCode}
            onChange={(e) => setAreaCode(e.target.value)}
            maxLength={3}
            width="200px"
          />
          <Select
            placeholder="Select Country"
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
            width="150px"
          >
            {countries.map((country) => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </Select>
          <Button
            colorScheme="blue"
            onClick={() => fetchAvailableNumbers(selectedCountry, areaCode)}
            isLoading={loading}
            rightIcon={<SearchIcon />}
          >
            Search
          </Button>
        </HStack>
        <Box borderWidth="1px" borderRadius="md">
          <Table variant="simple" width="500px">
            <Tbody>
              {availableNumbers.length > 0 ? (
                availableNumbers.map((number, index) => (
                  <Tr key={index}>
                    <Td>{number.friendly_name || number.phone_number}</Td>
                    <Td>$5/mo</Td>
                    <Td>
                      <Button
                        colorScheme="blue"
                        size="sm"
                        onClick={() => handleCheckout(number.phone_number)}
                        isLoading={isCheckoutLoading[number.phone_number]}
                      >
                        Buy
                      </Button>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={3} textAlign="center">No phone numbers for this area code</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>
      </VStack>
    </Box>
  );
};

export default PhoneNumbers;