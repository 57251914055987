import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Box, VStack, Text, HStack, Avatar, Input, Button, Heading } from '@chakra-ui/react';
import { getFirestore, doc, onSnapshot, collection, query, where, getDocs, updateDoc  } from 'firebase/firestore';
import axios from 'axios';

const MessageDetails = () => {
  const { workspaceId, messageId } = useParams();
  const [message, setMessage] = useState(null);
  const [contact, setContact] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    const fetchMessageAndContact = async () => {
      if (workspaceId && messageId) {
        try {
          const db = getFirestore();
          const messageRef = doc(db, 'workspaces', workspaceId, 'messages', messageId);

          // Listen for real-time updates on the message
          const unsubscribeMessage = onSnapshot(messageRef, (messageSnap) => {
            if (messageSnap.exists()) {
              setMessage({ id: messageSnap.id, ...messageSnap.data() });
            } else {
              console.log('No such message!');
            }
          });

          // Fetch the contact information
          const contactsRef = collection(db, 'workspaces', workspaceId, 'contacts');
          const q = query(contactsRef, where('phoneNumber', '==', messageId));
          const contactSnapshot = await getDocs(q);
          
          if (!contactSnapshot.empty) {
            const contactDoc = contactSnapshot.docs[0];
            setContact({ id: contactDoc.id, ...contactDoc.data() });
          }

          setLoading(false);

          // Mark message status as "read" after 3 seconds
          const timer = setTimeout(async () => {
            await updateDoc(messageRef, {
              status: "read"
            });
            console.log('Message status changed to read');
          }, 3000);

          // Clean up the listener and timer on component unmount
          return () => {
            unsubscribeMessage();
            clearTimeout(timer);
          };
        } catch (error) {
          console.error('Error fetching message and contact details:', error);
          setLoading(false);
        }
      }
    };

    fetchMessageAndContact();
  }, [workspaceId, messageId]);

  if (loading) {
    return <Box>Loading...</Box>;
  }

  if (!message) {
    return <Box>Message not found</Box>;
  }

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumber;
  };

  const handleSendMessage = async () => {
    console.log('New message:', newMessage);

    const lastSentMessage = message.messages.find(msg => msg.type === 'sending');
    if (lastSentMessage) {
      const payload = {
        message: {
          phoneNumber: { number: lastSentMessage.from },
          call: { customer: { number: lastSentMessage.to } },
          toolCalls: [
            { function: { arguments: { message: newMessage } } }
          ]
        }
      };

      try {
        await axios.post('https://api.clientbase.dev/message', payload);
        console.log('Message sent:', newMessage);
      } catch (error) {
        console.error('Error sending message:', error);
      }
    } else {
      console.error('No previous sent message found.');
    }

    setNewMessage('');
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  return (
    <Box position="relative" height="calc(100vh - 220px)" p={4}>
      <Heading as="h2" size="lg" mb={4}>
        {contact ? (
          <Link to={`/dashboard/${workspaceId}/contacts/${contact.id}`}>
            {contact.name || formatPhoneNumber(messageId)}
          </Link>
        ) : (
          formatPhoneNumber(messageId)
        )}
      </Heading>
      <VStack align="start" spacing={4} height="calc(100vh - 220px)" overflowY="scroll" width="100%" pb="75px"
        css={{
          '&::-webkit-scrollbar': {
            width: '0px',
          },
          '&::-webkit-scrollbar-track': {
            width: '0px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'transparent',
          },
        }}
      >
        {message.messages.map((msg, index) => (
          <HStack
            key={index}
            width="100%"
            justifyContent={msg.type === 'sending' ? 'flex-end' : 'flex-start'}
          >
            {msg.type === 'received' && <Avatar name="From" src="" />}
            <Box
              bg={msg.type === 'sending' ? 'blue.100' : 'gray.100'}
              p={3}
              borderRadius="md"
              maxWidth="80%"
            >
              <Text>{msg.body}</Text>
              <Text fontSize="xs" textAlign="right">
                {formatDate(msg.dateCreated)}
              </Text>
            </Box>
            {msg.type === 'sending' && <Avatar name="To" src="" />}
          </HStack>
        ))}
      </VStack>
      <Box position="absolute" bottom="-110px" width="100%" p={4} bg="white">
        <HStack width="100%">
          <Input
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Type your message here..."
            size="xl"
            p={2}
            bg="gray.200"
            borderRadius="md"
          />
          <Button onClick={handleSendMessage} colorScheme='blue'>Send</Button>
        </HStack>
      </Box>
    </Box>
  );
};

export default MessageDetails;