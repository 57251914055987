import React, { useState } from 'react';
import { Input, Button, VStack, Heading, Text, Link, InputGroup, InputLeftElement, Box, Alert, AlertIcon, Flex } from '@chakra-ui/react';
import { EmailIcon, LockIcon } from '@chakra-ui/icons';
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from '../Firebase';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, setDoc, serverTimestamp, collection, addDoc, getDoc } from 'firebase/firestore';
import logo from '../assets/logoicon.png';
import { FcGoogle } from 'react-icons/fc';
import RightSideContent from './widgets/RightSideContent';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const db = getFirestore();

  const createUserDocument = async (user, displayName = '') => {
    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      const workspaceRef = await addDoc(collection(db, 'workspaces'), {
        name: "My Workspace",
        totalMinutes: 15,
        status: 'free',
        planPriceIds: ["Free"],
        createdAt: serverTimestamp(),
        ownerId: user.uid,
        members: [
          {
            userId: user.uid,
            email: user.email,
            name: displayName,
            createdAt: new Date(),
            status: 'accepted',
            role: 'owner',
          },
        ],
      });

      await setDoc(userDocRef, {
        email: user.email,
        uid: user.uid,
        name: displayName,
        createdAt: serverTimestamp(),
        workspaces: [workspaceRef.id],
      });

      return workspaceRef.id;
    } else {
      const userData = userDoc.data();
      return userData.workspaces[0];
    }
  };

  const handleSignup = async () => {
    setError('');
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const workspaceId = await createUserDocument(userCredential.user);
      navigate(`/dashboard/${workspaceId}`);
    } catch (error) {
      setError(error.message);
      console.error("Error signing up:", error);
    }
  };

  const handleGoogleSignIn = async () => {
    setError('');
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const workspaceId = await createUserDocument(result.user, result.user.displayName);
      navigate(`/dashboard/${workspaceId}`);
    } catch (error) {
      setError(error.message);
      console.error("Error signing in with Google:", error);
    }
  };

  return (
    <Flex minH="100vh" flexDirection={{ base: 'column', lg: 'row' }}>
      <Flex flex={{ base: '1', lg: '2' }} justify="center" align="center" p={8}>
        <VStack spacing={4} w="full" maxW="md">
          <Box borderRadius="10px" overflow="hidden" boxSize="50px" cursor="pointer" onClick={() => window.location.href = 'https://clientbaseai.com'}>
            <img src={logo} alt="Clientbase Logo" style={{ width: 'full', height: 'auto' }} />
          </Box>
          <Heading>Sign Up</Heading>
          {error && (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          )}
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <EmailIcon color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <LockIcon color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </InputGroup>
          <Button colorScheme="blue" size="lg" w="full" onClick={handleSignup}>Sign Up</Button>
          <Button leftIcon={<FcGoogle />} colorScheme="gray" size="lg" w="full" onClick={handleGoogleSignIn}>
            Continue with Google
          </Button>
          <Text>
            Already have an account? <Link color="blue.500" onClick={() => navigate('/login')}>Click here to login</Link>
          </Text>
        </VStack>
      </Flex>
      <Box flex={{ base: '1', lg: '3' }} display={{ base: 'none', lg: 'block' }} bg="#222222">
        <RightSideContent />  {/* Use the new component here */}
      </Box>
    </Flex>
  );
};

export default Signup;
