import React, { useEffect, useState } from 'react';
import { Box, Text, Button, Progress, HStack, Badge, useToast } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../../Firebase';

const UsageAndUpgrade = () => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const [planStatus, setPlanStatus] = useState('');
  const [workspaceData, setWorkspaceData] = useState(null);
  const [isEndingTrial, setIsEndingTrial] = useState(false);
  const [totalUsageMinutes, setTotalUsageMinutes] = useState(0);
  const toast = useToast();

  useEffect(() => {
    const fetchUsageData = async () => {
      try {
        const usageRef = collection(db, 'workspaces', workspaceId, 'usage');
        const snapshot = await getDocs(usageRef);

        let totalMilliseconds = 0;
        snapshot.forEach((doc) => {
          const usageData = doc.data();
          if (usageData.calls && Array.isArray(usageData.calls)) {
            usageData.calls.forEach((call) => {
              totalMilliseconds += call.secondsFromStart || 0;
            });
          }
        });

        // Convert milliseconds to minutes and round to the nearest minute
        const totalMinutes = Math.round(totalMilliseconds / 1000 / 60);
        setTotalUsageMinutes(totalMinutes);

        // Fetch workspace document to get plan status and other data
        const workspaceDoc = await getDoc(doc(db, 'workspaces', workspaceId));
        if (workspaceDoc.exists()) {
          const data = workspaceDoc.data();
          setPlanStatus(data.status || 'Unknown');
          setWorkspaceData(data);
        }
      } catch (error) {
        console.error("Error fetching usage data:", error);
      }
    };

    fetchUsageData();
  }, [workspaceId]);

  const handleUpgradeClick = () => {
    navigate(`/dashboard/${workspaceId}/settings/plan-billing`);
  };

  const handleEndTrial = async () => {
    setIsEndingTrial(true);
    const endTrialSubscription = httpsCallable(functions, "endTrialSub");

    try {
      const result = await endTrialSubscription({
        subscriptionId: workspaceData?.planSubscriptionId,
      });
      console.log("Trial ended successfully:", result.data);
      toast({
        title: "Success",
        description: "Trial ended successfully. The page will refresh shortly.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error("Error ending trial:", error);
      toast({
        title: "Error",
        description: "Failed to end trial. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsEndingTrial(false);
    }
  };

  const getStatusBadgeColor = (status) => {
    switch (status.toLowerCase()) {
      case 'free':
        return 'gray';
      case 'trialing':
        return 'orange';
      case 'active':
        return 'green';
      default:
        return 'gray';
    }
  };

  const totalMinutes = workspaceData?.totalMinutes || 1800;

  return (
    <Box mt="auto" w="100%">
      <HStack justifyContent="space-between" alignItems="center">
        <Badge colorScheme={getStatusBadgeColor(planStatus)}>
          {planStatus}
        </Badge>
        <Text fontSize="md" color="gray.600">
          {`${totalUsageMinutes} / ${totalMinutes} Minutes`}
        </Text>
      </HStack>
      <Progress
        value={(totalUsageMinutes / totalMinutes) * 100}
        size="lg"
        colorScheme='green'
        mt={2}
        bg="gray.200"
        borderRadius={25}
      />
      {planStatus.toLowerCase() === 'free' && (
        <Button mt={4} colorScheme="blue" w="100%" onClick={handleUpgradeClick}>
          Upgrade
        </Button>
      )}
      {planStatus.toLowerCase() === 'trialing' && (
        <Button mt={4} colorScheme="orange" w="100%" onClick={handleEndTrial} isLoading={isEndingTrial}>
          End Trial
        </Button>
      )}
    </Box>
  );
};

export default UsageAndUpgrade;