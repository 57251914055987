import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  SimpleGrid,
  Text,
  Badge,
  Flex,
  Spinner,
  Alert,
  AlertIcon,
  Button,
} from '@chakra-ui/react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { useAuth } from '../../AuthContext';
import { useParams } from 'react-router-dom';

const PhoneNumbersDisplay = () => {
  const { currentUser } = useAuth();
  const { workspaceId } = useParams();
  const [phoneNumberSubscriptions, setPhoneNumberSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadingPortal, setLoadingPortal] = useState(false);
  const db = getFirestore();
  const functions = getFunctions();

  useEffect(() => {
    if (currentUser && workspaceId) {
      const fetchWorkspaceData = async () => {
        try {
          const workspaceRef = doc(db, 'workspaces', workspaceId);
          const workspaceSnap = await getDoc(workspaceRef);

          if (workspaceSnap.exists()) {
            setPhoneNumberSubscriptions(workspaceSnap.data().phoneNumberSubscriptions || []);
          } else {
            throw new Error('Workspace document does not exist');
          }
        } catch (error) {
          console.error('Error fetching workspace data:', error);
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };

      fetchWorkspaceData();
    } else {
      setLoading(false);
    }
  }, [currentUser, workspaceId, db]);

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '+' + match[1] + ' (' + match[2] + ') ' + match[3] + '-' + match[4];
    }
    return phoneNumber;
  };

  const handleBillingPortal = async (customerId) => {
    if (!customerId) {
      console.error('Error: No Stripe customer ID found.');
      return;
    }

    setLoadingPortal(true);

    const createBillingPortalSession = httpsCallable(functions, 'createBillingPortalSession');
    const returnUrl = `${window.location.origin}/dashboard/${workspaceId}`;

    try {
      const result = await createBillingPortalSession({ customerId, returnUrl });
      const session = result.data;
      window.location.href = session.url;
    } catch (error) {
      console.error('Error:', error);
      setLoadingPortal(false);
    }
  };

  if (loading) {
    return <Spinner size="xl" />;
  }

  if (error) {
    return (
      <Alert status="error">
        <AlertIcon />
        {error}
      </Alert>
    );
  }

  return (
    <Box maxW="500px" textAlign="left">
      <Heading mt={4} size="md" mb={4}>
        Active Phone Numbers
      </Heading>
      {phoneNumberSubscriptions.length > 0 ? (
        <SimpleGrid columns={1} spacing={4}>
          {phoneNumberSubscriptions.map((subscription, index) => (
            <Box
              key={index}
              borderWidth="1px"
              borderRadius="md"
              p={4}
            >
              <Flex justify="space-between" align="center">
                <Flex align="center">
                  <Text fontSize="lg" fontWeight="bold" mr={2}>
                    {formatPhoneNumber(subscription.phoneNumber)}
                  </Text>
                  <Badge colorScheme={subscription.status === 'active' ? 'green' : 'red'}>
                    {subscription.status}
                  </Badge>
                </Flex>
                <Button
                  onClick={() => handleBillingPortal(subscription.customerId)}
                  isLoading={loadingPortal}
                >
                  Manage
                </Button>
              </Flex>
            </Box>
          ))}
        </SimpleGrid>
      ) : (
        <Box
          borderWidth="1px"
          borderRadius="md"
          p={4}
          textAlign="center"
        >
          <Text>No phone numbers found.</Text>
        </Box>
      )}
    </Box>
  );
};

export default PhoneNumbersDisplay;
