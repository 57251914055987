import React from 'react';
import { Box, Icon } from '@chakra-ui/react';
import { Handle, Position } from 'reactflow';
import { FaPlay } from 'react-icons/fa';
import { useNodeContext } from '../NodeContext';

const StartNode = ({ id, data }) => {
  const { selectedNode, setSelectedNode } = useNodeContext();

  const handleClick = () => {
    setSelectedNode({ id, type: 'startNode', label: data.label });
  };

  return (
    <Box
      onClick={handleClick}
      style={{
        backgroundColor: 'white',
        padding: '16px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        fontWeight: 'bold',
        color: '#222222',
        border: `2px solid ${selectedNode && selectedNode.id === id ? '#3182CE' : '#CBD5E0'}`,
        borderRadius: '10px',
        position: 'relative',
      }}
    >
      <Box display="flex" alignItems="center">
        <Icon as={FaPlay} w={6} h={6} color="#5eff7e" />
        <span style={{ marginLeft: '8px' }}>Greeting</span>
      </Box>
      <Handle
        type="source"
        position={Position.Right}
        style={{
          top: '50%',
          background: '#CBD5E0',
          width: 10,
          height: 10,
          borderRadius: '50%',
          transform: 'translateY(-50%)',
          right: '-6px',
        }}
        id="start-node-handle"
      />
    </Box>
  );
};

export default StartNode;
