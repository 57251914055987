// components/Permissions.js
import React from 'react';
import { Container, Heading } from '@chakra-ui/react';

const Permissions = () => {
  return (
    <Container centerContent>
      <Heading>Permissions Settings</Heading>
    </Container>
  );
};

export default Permissions;