import React, { useEffect, useState } from 'react';
import { 
  Box, 
  Text, 
  Avatar, 
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  IconButton, 
  useDisclosure, 
  Button, 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalFooter, 
  ModalBody, 
  ModalCloseButton 
} from '@chakra-ui/react';
import { FaChevronDown } from 'react-icons/fa';
import { useAuth } from '../AuthContext';
import { signOut } from 'firebase/auth';
import { auth } from '../../Firebase';
import { useNavigate } from 'react-router-dom';
import getUserWorkspaces from '../utils/getUserWorkspaces';
import { FiBarChart, FiExternalLink, FiLogOut, FiPhone, FiSettings } from 'react-icons/fi';

const SearchBar = () => {
  const { currentUser } = useAuth();
  const email = currentUser?.email || '';
  const initials = email.split('@')[0].slice(0, 2).toUpperCase();
  const navigate = useNavigate();
  const [workspaceId, setWorkspaceId] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchWorkspaces = async () => {
      if (currentUser) {
        try {
          const workspaces = await getUserWorkspaces(currentUser.uid);
          if (workspaces.length > 0) {
            setWorkspaceId(workspaces[0]);
          }
        } catch (error) {
          console.error('Error fetching workspaces:', error);
        }
      }
    };

    fetchWorkspaces();
  }, [currentUser]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleSettingsClick = () => {
    navigate(`/dashboard/${workspaceId}/settings/general`);
  };

  return (
    <Box borderBottomWidth="1px" borderColor="gray.200" p={4} display="flex" alignItems="center" justifyContent="flex-end">
      <Avatar bg="blue.500" color="white" name={email} mr={4}>
        {initials}
      </Avatar>
      <Text mr={4}>{email}</Text>
      <Menu>
        <MenuButton as={IconButton} icon={<FaChevronDown />} variant="outline" />
        <MenuList>
          <MenuItem icon={<FiExternalLink size="16px"/>} as="a" href="https://clientbaseai.com" target="_blank" rel="noopener noreferrer">Home Page</MenuItem>
          <MenuItem icon={<FiBarChart size="16px"/>} as="a" href="https://clientbase.tolt.io/login" target="_blank" rel="noopener noreferrer">Affiliates</MenuItem>
          <MenuItem icon={<FiPhone size="16px"/>} as="a" href="https://clientbaseai.com/contact/" target="_blank" rel="noopener noreferrer">Contact</MenuItem>
          <MenuItem icon={<FiSettings size="16px"/>} onClick={handleSettingsClick}>Settings</MenuItem>
          <MenuItem icon={<FiLogOut size="16px"/>} onClick={onOpen}>Logout</MenuItem>
        </MenuList>
      </Menu>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Logout</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to logout?
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleLogout}>Logout</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SearchBar;
