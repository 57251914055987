// src/utils/getUserWorkspaces.js
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const getUserWorkspaces = async (userId) => {
  const db = getFirestore();
  const userDoc = await getDoc(doc(db, 'users', userId));
  return userDoc.exists() ? userDoc.data().workspaces : [];
};

export default getUserWorkspaces;
