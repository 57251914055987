import React from 'react';
import { Box } from '@chakra-ui/react';
import PlanDetails from './planbilling/PlanDetails';
import PricingTable from './planbilling/PricingTable';

const PlanBilling = () => {

  return (
    <Box
      height="calc(100vh - 120px)"
      overflowY="scroll"
      css={{
        '&::-webkit-scrollbar': {
          width: '0px',
        },
        '&::-webkit-scrollbar-track': {
          width: '0px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'transparent',
        },
      }}
    >
      <PlanDetails />
      <PricingTable />
    </Box>
  );
};

export default PlanBilling;
