import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Button,
  Grid,
  Spinner,
  useToast,
  Switch,
  FormControl,
  FormLabel,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { functions, auth } from "../../../../Firebase";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { monthlyPricingPlan, yearlyPricingPlan } from "./PricingPlans";
import { FaCheck } from "react-icons/fa"; // Import the checkmark icon

const PricingTable = () => {
  const [loadingPlan, setLoadingPlan] = useState(null);
  const [workspaceData, setWorkspaceData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isYearly, setIsYearly] = useState(true); // Default to yearly
  const { workspaceId } = useParams();
  const db = getFirestore();
  const toast = useToast();

  useEffect(() => {
    const fetchWorkspaceDetails = async () => {
      setIsLoading(true);
      if (auth.currentUser) {
        const workspaceDoc = await getDoc(doc(db, "workspaces", workspaceId));
        if (workspaceDoc.exists()) {
          const data = workspaceDoc.data();
          console.log("planPriceIds:", data.planPriceIds);
          setWorkspaceData(data);

          const isYearlyPlan = yearlyPricingPlan.some(
            (plan) =>
              plan.monthlyPriceId &&
              data.planPriceIds?.includes(plan.monthlyPriceId)
          );
          setIsYearly(isYearlyPlan || true); // Ensure it defaults to yearly
        }
      }
      setIsLoading(false);
    };

    fetchWorkspaceDetails();
  }, [db, workspaceId]);

  const handleSubscription = async (
    monthlyPriceId,
    unitPriceId,
    totalMinutes,
    isSwitch = false
  ) => {
    setLoadingPlan(monthlyPriceId);
    const createCheckoutSession = httpsCallable(
      functions,
      "createCheckoutSession"
    );
    const baseUrl = window.location.origin;
    const toltReferral = window.tolt_referral; // Get Tolt referral ID

    try {
      const result = await createCheckoutSession({
        monthlyPriceId,
        unitPriceId,
        baseUrl,
        workspaceId,
        customerId: workspaceData?.customerId,
        ownerUserId: workspaceData?.ownerId,
        ownerEmail: workspaceData?.members?.find((m) => m.role === "owner")
          ?.email,
        totalMinutes,
        isSwitch,
        toltReferral, // Pass Tolt referral ID
      });
      window.location.href = result.data.url;
    } catch (error) {
      console.error("Error creating checkout session:", error);
      toast({
        title: "Error",
        description: "Failed to create checkout session. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoadingPlan(null);
    }
  };

  const handleEndTrial = async () => {
    setLoadingPlan("endTrial");
    const endTrialSubscription = httpsCallable(functions, "endTrialSub");

    try {
      const result = await endTrialSubscription({
        subscriptionId: workspaceData?.planSubscriptionId,
      });
      console.log("Trial ended successfully:", result.data);
      toast({
        title: "Success",
        description: "Trial ended successfully. The page will refresh shortly.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error("Error ending trial:", error);
      toast({
        title: "Error",
        description: "Failed to end trial. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoadingPlan(null);
    }
  };

  const currentPricingPlan = useMemo(
    () => (isYearly ? yearlyPricingPlan : monthlyPricingPlan),
    [isYearly]
  );

  const isCurrentPlan = (plan) => {
    if (!workspaceData?.planPriceIds) {
      return false;
    }
    return workspaceData.planPriceIds.includes(plan.monthlyPriceId);
  };

  const isTrialing = () => {
    return workspaceData?.status === "trialing";
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box>
      <FormControl display="flex" alignItems="center" mb={8}>
        <FormLabel htmlFor="pricing-period" mb="0">
          Yearly
        </FormLabel>
        <Switch
          id="pricing-period"
          isChecked={!isYearly} // Display Monthly as "on" when checked
          onChange={() => setIsYearly(!isYearly)}
          mr={3}
        />
        <FormLabel htmlFor="pricing-period" mb="0">
          Monthly
        </FormLabel>
      </FormControl>

      <Grid
        templateColumns={{
          base: "1fr",
          sm: "repeat(auto-fit, minmax(250px, 1fr))",
          md: "repeat(auto-fit, minmax(300px, 1fr))",
          lg: "repeat(auto-fit, minmax(250px, 1fr))",
        }}
        gap={8}
      >
        {currentPricingPlan.map((plan) => (
          <Box
            key={plan.name}
            borderWidth="1px"
            borderRadius="lg"
            p={12}
            textAlign="center"
          >
            <Heading size="md" mb={4}>
              {plan.name}
            </Heading>
            <Text mb={4} fontSize="32px" fontWeight="bold">
              {plan.price}
            </Text>
            <Button
              mb={6}
              size="xl"
              colorScheme={
                isTrialing() && isCurrentPlan(plan) ? "orange" : "blue"
              }
              onClick={() =>
                plan.contactUs
                  ? (window.location.href = "http://clientbaseai.com/contact/")
                  : isTrialing() && isCurrentPlan(plan)
                  ? handleEndTrial()
                  : handleSubscription(
                      plan.monthlyPriceId,
                      plan.unitPriceId,
                      plan.totalMinutes,
                      isTrialing() && !isCurrentPlan(plan)
                    )
              }
              isLoading={loadingPlan === plan.monthlyPriceId || loadingPlan === "endTrial"}
              isDisabled={!isTrialing() && isCurrentPlan(plan)}
            >
              {plan.contactUs
                ? "Contact Us"
                : isTrialing() && isCurrentPlan(plan)
                ? "End Trial"
                : isTrialing() && !isCurrentPlan(plan)
                ? "Switch Plan"
                : isCurrentPlan(plan)
                ? "Current Plan"
                : "Subscribe"}
            </Button>
            <VStack spacing={2} align="start">
              {plan.features.map((feature, index) => (
                <HStack key={index} align="start">
                  <Icon as={FaCheck} color="blue.500" />
                  <Text>{feature}</Text>
                </HStack>
              ))}
            </VStack>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default PricingTable;
