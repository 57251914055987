// components/Contacts.js
import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Text,
  Flex,
  Input,
  VStack,
  InputGroup,
  InputLeftElement,
  Icon,
  Spinner
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useAuth } from '../AuthContext';
import getUserWorkspaces from '../utils/getUserWorkspaces';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { useNavigate, Outlet } from 'react-router-dom';
import { FiUser } from 'react-icons/fi';

const Contacts = () => {
  const { currentUser } = useAuth();
  const [contacts, setContacts] = useState([]);
  const [workspaceId, setWorkspaceId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContacts = async () => {
      if (currentUser) {
        try {
          const workspaces = await getUserWorkspaces(currentUser.uid);
          if (workspaces.length > 0) {
            const workspaceId = workspaces[0];
            setWorkspaceId(workspaceId);
            console.log('Workspace ID:', workspaceId);

            const db = getFirestore();
            const contactsQuery = collection(db, 'workspaces', workspaceId, 'contacts');
            const querySnapshot = await getDocs(contactsQuery);
            const contactsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setContacts(contactsData);
            setFilteredContacts(contactsData);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching contacts:', error);
          setLoading(false);
        }
      }
    };

    fetchContacts();
  }, [currentUser]);

  useEffect(() => {
    const filterContacts = () => {
      if (!searchTerm) {
        setFilteredContacts(contacts);
      } else {
        const lowercasedTerm = searchTerm.toLowerCase();
        const filtered = contacts.filter(contact =>
          (contact.firstName && contact.firstName.toLowerCase().includes(lowercasedTerm)) ||
          (contact.middleName && contact.middleName.toLowerCase().includes(lowercasedTerm)) ||
          (contact.lastName && contact.lastName.toLowerCase().includes(lowercasedTerm)) ||
          (contact.phoneNumber && contact.phoneNumber.toLowerCase().includes(lowercasedTerm))
        );
        setFilteredContacts(filtered);
      }
    };

    filterContacts();
  }, [searchTerm, contacts]);

  const handleContactClick = (contactId) => {
    navigate(`/dashboard/${workspaceId}/contacts/${contactId}`);
  };

  return (
    <Box display="flex" height="100%">
      <VStack align="stretch" width="400px" height="100%" p={4} borderRight="1px" borderRightColor="gray.200">
        <Flex align="center">
          <FiUser size={30} style={{ marginRight: '12px' }} />
          <Heading as="h2" size="xl">
            Contacts
          </Heading>
        </Flex>
        <Text fontSize="xl" fontWeight="bold" mb={2}>
          {filteredContacts.length} Contacts
        </Text>
        <InputGroup size="lg">
          <InputLeftElement pointerEvents="none">
            <Icon as={SearchIcon} color="gray.400" />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search by name or number"
            bg="white"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
        <Box
          height="calc(100vh - 266px)"
          overflowY="scroll"
          css={{
            '&::-webkit-scrollbar': {
              width: '0px',
            },
            '&::-webkit-scrollbar-track': {
              width: '0px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'transparent',
            },
          }}
        >
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <Spinner />
            </Box>
          ) : (
            <VStack spacing={2} align="stretch">
              {filteredContacts.length > 0 ? (
                filteredContacts.map((contact) => (
                  <Box
                    key={contact.id}
                    p={2}
                    borderWidth="1px"
                    borderRadius="md"
                    onClick={() => handleContactClick(contact.id)}
                    cursor="pointer"
                    _hover={{ bg: 'gray.100' }}
                  >
                    {contact.firstName || contact.middleName || contact.lastName ? (
                      <>
                        <Text fontWeight="bold">
                          {contact.firstName} {contact.middleName} {contact.lastName}
                        </Text>
                        <Text fontWeight="bold">{contact.phoneNumber}</Text>
                      </>
                    ) : (
                      <Text fontWeight="bold">{contact.phoneNumber}</Text>
                    )}
                    <Text fontSize="sm">Last Call At: {contact.lastCallAt.toDate().toLocaleString()}</Text>
                  </Box>
                ))
              ) : (
                <Text>No contacts found.</Text>
              )}
            </VStack>
          )}
        </Box>
      </VStack>
      <Box flex="1" p={4}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Contacts;