import React, { useState, useEffect } from "react";
import {
  Heading,
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Box,
  useToast, // Import useToast for displaying toast messages
  Flex, // Import Flex for layout
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import axios from "axios";
import { useAuth } from "../../AuthContext";
import { useParams } from "react-router-dom";
import {
  getFirestore,
  doc,
  onSnapshot,
  updateDoc,
  arrayRemove,
} from "firebase/firestore";

const Members = () => {
  const [email, setEmail] = useState("");
  const [members, setMembers] = useState([]);
  const [workspaceOwnerId, setWorkspaceOwnerId] = useState(null);
  const { workspaceId } = useParams();
  const { currentUser } = useAuth();
  const toast = useToast(); // Initialize useToast

  useEffect(() => {
    if (currentUser && workspaceId) {
      console.log("Current user ID:", currentUser.uid);
      console.log("Subscribing to members for workspace ID:", workspaceId);
      const db = getFirestore();
      const workspaceDoc = doc(db, "workspaces", workspaceId);

      const unsubscribe = onSnapshot(workspaceDoc, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const workspaceData = docSnapshot.data();
          const membersList = workspaceData.members;
          setMembers(membersList);
          setWorkspaceOwnerId(workspaceData.ownerId);
          console.log("Fetched members:", membersList);
        } else {
          console.warn("Workspace not found");
        }
      });

      return () => unsubscribe();
    } else {
      console.warn("No current user or workspace ID found.");
    }
  }, [currentUser, workspaceId]);

  const handleInvite = async () => {
    console.log(
      "Generating invite for email:",
      email,
      "workspace ID:",
      workspaceId
    );
    try {
      // Generate the invite link
      const response = await axios.post(
        "https://api.clientbase.dev/generate-invite-link",
        {
          email,
          workspaceId,
        }
      );
      console.log("Invite generated:", response.data);

      // Send the invite email
      await axios.post("https://api.clientbase.dev/send-email", {
        email,
        magicLink: response.data.inviteLink,
      });
      console.log("Email sent to:", email);

      // Show success toast
      toast({
        title: "Invite Sent",
        description: `An invitation has been sent to ${email}.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Clear the email input
      setEmail("");
    } catch (error) {
      console.error("Error generating invite link or sending email:", error);
      // Show error toast
      toast({
        title: "Error",
        description: "Failed to send invite. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const removeMember = async (member) => {
    try {
      const db = getFirestore();

      // Remove from workspace members array
      const workspaceDoc = doc(db, "workspaces", workspaceId);
      await updateDoc(workspaceDoc, {
        members: arrayRemove(member),
      });

      // Remove from user's workspaces array
      if (member.userId) {
        const userDoc = doc(db, "users", member.userId);
        await updateDoc(userDoc, {
          workspaces: arrayRemove(workspaceId),
        });
      }

      console.log(`Removed member: ${member.email}`);
    } catch (error) {
      console.error("Error removing member:", error);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Box>
      <Heading>Members Settings</Heading>
      <Flex mb={4}>
        <Input
          placeholder="Enter email to invite"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          mr={2} // Add right margin to space out the input and button
        />
        <Button colorScheme="blue" onClick={handleInvite} isDisabled={!workspaceId || !email.trim()}>
          Add Member
        </Button>
      </Flex>
      <Heading size="md" mt={6} mb={4}>
        Members
      </Heading>
      <Box borderWidth="1px" borderRadius="md">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th textAlign="left">Email</Th>
              <Th textAlign="left">Status</Th>
              <Th textAlign="left">Role</Th>
              <Th textAlign="left">Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {members.map((member) => (
              <Tr key={member.userId ? member.userId : member.email}>
                <Td textAlign="left">{member.email}</Td>
                <Td textAlign="left">{capitalizeFirstLetter(member.status)}</Td>
                <Td textAlign="left">{capitalizeFirstLetter(member.role)}</Td>
                <Td textAlign="left">
                  {currentUser.uid === workspaceOwnerId ? (
                    <IconButton
                      icon={<CloseIcon />}
                      colorScheme="red"
                      onClick={() => removeMember(member)}
                      aria-label="Remove Member"
                      isDisabled={member.userId === workspaceOwnerId}
                    />
                  ) : (
                    (currentUser.uid === member.userId ||
                      currentUser.uid === workspaceOwnerId) &&
                    member.userId !== workspaceOwnerId && (
                      <IconButton
                        icon={<CloseIcon />}
                        colorScheme="red"
                        onClick={() => removeMember(member)}
                        aria-label="Remove Member"
                      />
                    )
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default Members;
