import React, { useState, useRef } from "react";
import {
  Box,
  Text,
  FormControl,
  FormLabel,
  Switch,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  IconButton,
  useToast,
  HStack,
  VStack,
  Input,
} from "@chakra-ui/react";
import { SketchPicker } from "react-color";
import { CopyIcon } from "@chakra-ui/icons";

const FormOptions = ({ formOptions, setFormOptions, workspaceId, agentId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentColorPicker, setCurrentColorPicker] = useState(null);
  const embedTextareaRef = useRef(null);
  const toast = useToast();

  const handleOpenColorPicker = (colorType) => {
    setCurrentColorPicker(colorType);
    onOpen();
  };

  const handleColorChangeComplete = (color) => {
    if (currentColorPicker === "bgColor") {
      setFormOptions({ ...formOptions, bgColor: color.hex });
    } else if (currentColorPicker === "buttonColor") {
      setFormOptions({ ...formOptions, buttonColor: color.hex });
    }
    onClose();
  };

  const handleCopyEmbed = () => {
    if (embedTextareaRef.current) {
      embedTextareaRef.current.select();
      document.execCommand("copy");
      toast({
        title: "Copied embed to clipboard",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const embedCode = `<div id="clientbase-form-${agentId}"></div><script src="https://clientbaseserver.onrender.com/embed.js" data-workspace-id="${workspaceId}" data-agent-id="${agentId}"></script>`;

  return (
    <Box
      bg="white"
      p={4}
      borderWidth="1px"
      borderColor="gray.200"
      borderRadius="md"
    >
      <Text fontWeight="bold" fontSize="24px" mb={4}>
        Advanced Fields
      </Text>
      <VStack spacing={4} alignItems="stretch">
        <FormControl>
          <FormLabel htmlFor="first-message">Greeting Message</FormLabel>
          <Input
            id="first-message"
            value={formOptions.firstMessage}
            onChange={(e) =>
              setFormOptions({ ...formOptions, firstMessage: e.target.value })
            }
            placeholder="Enter the first greeting message of the form"
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="sms-message">
            Send Text Message on Complete
          </FormLabel>
          <Input
            id="sms-message"
            value={formOptions.smsMessage || ""}
            onChange={(e) =>
              setFormOptions({ ...formOptions, smsMessage: e.target.value })
            }
            placeholder="Enter SMS message (leave empty to not send one)"
          />
        </FormControl>
        <HStack spacing={4} alignItems="center">
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="show-title" mb="0" mr={2}>
              Show Form Title
            </FormLabel>
            <Switch
              id="show-title"
              isChecked={formOptions.showTitle}
              onChange={(e) =>
                setFormOptions({ ...formOptions, showTitle: e.target.checked })
              }
            />
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="call-after" mb="0" mr={2}>
              Call After Submit
            </FormLabel>
            <Switch
              id="call-after"
              isChecked={formOptions.callAfter}
              onChange={(e) =>
                setFormOptions({ ...formOptions, callAfter: e.target.checked })
              }
            />
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="bg-color-picker" mb="0" mr={2}>
              Background Color
            </FormLabel>
            <Box
              id="bg-color-picker"
              onClick={() => handleOpenColorPicker("bgColor")}
              cursor="pointer"
            >
              <Box
                width="36px"
                height="36px"
                borderRadius="4px"
                backgroundColor={formOptions.bgColor}
                border="1px solid #ccc"
              />
            </Box>
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="button-color-picker" mb="0" mr={2}>
              Button Color
            </FormLabel>
            <Box
              id="button-color-picker"
              onClick={() => handleOpenColorPicker("buttonColor")}
              cursor="pointer"
            >
              <Box
                width="36px"
                height="36px"
                borderRadius="4px"
                backgroundColor={formOptions.buttonColor}
                border="1px solid #ccc"
              />
            </Box>
          </FormControl>
        </HStack>
        <HStack spacing={4} alignItems="center">
          <FormControl display="flex" flexDirection="column" alignItems="start">
            <FormLabel htmlFor="border-radius" mb={1}>
              Border Radius
            </FormLabel>
            <NumberInput
              id="border-radius"
              value={formOptions.borderRadius}
              onChange={(valueString) =>
                setFormOptions({
                  ...formOptions,
                  borderRadius: parseInt(valueString),
                })
              }
              min={0}
              max={50}
              w="100%"
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="delay-timer">Delay Timer (seconds)</FormLabel>
            <NumberInput
              id="delay-timer"
              value={formOptions.delayTimer}
              onChange={(valueString) =>
                setFormOptions({
                  ...formOptions,
                  delayTimer: parseInt(valueString),
                })
              }
              min={0}
              max={60}
              step={1}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        </HStack>
        <FormControl>
          <FormLabel>Embed Code</FormLabel>
          <HStack position="relative">
            <Textarea
              ref={embedTextareaRef}
              h="90px"
              value={embedCode}
              isReadOnly
              pr="40px"
            />
            <IconButton
              icon={<CopyIcon boxSize={6} />}
              aria-label="Copy embed code"
              h="90px"
              w="50px"
              onClick={handleCopyEmbed}
            />
          </HStack>
        </FormControl>
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Color</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SketchPicker
              color={
                currentColorPicker === "bgColor"
                  ? formOptions.bgColor
                  : formOptions.buttonColor
              }
              onChangeComplete={handleColorChangeComplete}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default FormOptions;
