// components/Domain.js
import React from 'react';
import { Container, Heading } from '@chakra-ui/react';

const Domain = () => {
  return (
    <Container centerContent>
      <Heading>Domain Settings</Heading>
    </Container>
  );
};

export default Domain;