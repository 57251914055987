import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../../../Firebase";
import { doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import {
  Box,
  Heading,
  Spinner,
  Text,
  VStack,
  HStack,
  Avatar,
  Flex,
  Badge,
  Icon,
  useColorModeValue,
  Grid,
  GridItem,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import {
  FaPhoneAlt,
  FaClock,
  FaCalendarAlt,
} from "react-icons/fa";
import { useAuth } from "../../AuthContext";
import { FiPhoneCall } from "react-icons/fi";

const CallDetail = () => {
  const { workspaceId, callId } = useParams();
  const [callLog, setCallLog] = useState(null);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const audioRef = useRef(null);
  const navigate = useNavigate();
  const toast = useToast();

  const cardBg = useColorModeValue("white", "gray.800");

  const formatPhoneNumber = useCallback((phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    if (cleaned.length === 10) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(
        6
      )}`;
    } else if (cleaned.length === 11) {
      return `+${cleaned.slice(0, 1)} (${cleaned.slice(1, 4)}) ${cleaned.slice(
        4,
        7
      )}-${cleaned.slice(7)}`;
    }
    return phoneNumber; // Return original if not 10 or 11 digits
  }, []);

  const getOrdinalSuffix = useCallback((day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }, []);

  const formatDate = useCallback(
    (dateString) => {
      const date = new Date(dateString);
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

      return `${month} ${day}${getOrdinalSuffix(
        day
      )}, ${year} ${formattedHours}:${formattedMinutes} ${ampm}`;
    },
    [getOrdinalSuffix]
  );

  const formatCallDuration = (durationInSeconds) => {
    if (durationInSeconds < 60) {
      return `${durationInSeconds.toFixed(2)} seconds`;
    } else {
      const minutes = (durationInSeconds / 60).toFixed(2);
      return `${minutes} minutes`;
    }
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const fetchCallLog = async () => {
      const callLogRef = doc(db, `workspaces/${workspaceId}/logs`, callId);
      const callLogSnap = await getDoc(callLogRef);
      if (callLogSnap.exists()) {
        setCallLog(callLogSnap.data().message);
      } else {
        console.log("No such document!");
      }
      setLoading(false);
    };

    fetchCallLog();
  }, [workspaceId, callId, currentUser]);

  const handleCustomerNumberClick = async () => {
    const contactsRef = collection(db, `workspaces/${workspaceId}/contacts`);
    const q = query(contactsRef, where("phoneNumber", "==", callLog.customer.number));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        navigate(`/dashboard/${workspaceId}/contacts/${doc.id}`);
      });
    } else {
      toast({
        title: "No customer exists",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="calc(100vh - 120px)">
        <Spinner size="xl" color="blue.500" thickness="4px" />
      </Flex>
    );
  }

  if (!callLog) {
    return <Text>No call log found</Text>;
  }

  const callDuration = callLog.messages.reduce(
    (max, msg) => Math.max(max, msg.secondsFromStart),
    0
  );
  const formattedCallDuration = formatCallDuration(callDuration);

  const messagesWithoutFirst = callLog.messages.slice(1);

  return (
    <Box height="calc(100vh - 120px)">
      <VStack align="stretch">
        <HStack mb={4} width="100%">
          <Heading as="h2" size="xl">
            Call Details
          </Heading>
          <Spacer />
          <Box display="flex" alignItems="center">
            <Text fontSize="sm" color="gray.500">
              {callLog.call.id}
            </Text>
          </Box>
        </HStack>

        <Box mb={2}>
          <Grid templateColumns="repeat(4, 1fr)" gap={4}>
            <GridItem colSpan={1}>
              <Box borderWidth="1px" p={4} borderRadius="md" h="100%">
                <HStack mb={2}>
                  <Icon as={FaCalendarAlt} color="green.500" />
                  <Text fontWeight="bold" fontSize="18px" color="green.700">
                    Time Stamp
                  </Text>
                </HStack>
                <Text fontSize="20px" fontWeight="bold">
                  {formatDate(callLog.timestamp)}
                </Text>
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box borderWidth="1px" p={4} borderRadius="md" h="100%">
                <HStack mb={2}>
                  <Icon as={FaClock} color="orange.500" />
                  <Text fontWeight="bold" fontSize="18px" color="orange.700">
                    Call Duration
                  </Text>
                </HStack>
                <Text fontSize="20px" fontWeight="bold">
                  {formattedCallDuration}
                </Text>
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box
                borderWidth="1px"
                p={4}
                borderRadius="md"
                h="100%"
                cursor="pointer"
                _hover={{ bg: "gray.100" }}
                onClick={handleCustomerNumberClick}
              >
                <HStack mb={2}>
                  <Icon as={FaPhoneAlt} color="blue.500" />
                  <Text fontWeight="bold" fontSize="18px" color="blue.700">
                    Customer Number
                  </Text>
                </HStack>
                <Text fontSize="20px" fontWeight="bold">
                  {formatPhoneNumber(callLog.customer.number)}
                </Text>
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box p={4} borderRadius="md" h="100%" borderWidth="1px">
                <HStack mb={2}>
                  <Icon as={FiPhoneCall} color="red.500" />
                  <Text fontWeight="bold" fontSize="18px" color="red.700">
                    Call Type
                  </Text>
                </HStack>
                <Badge fontSize="20px" fontWeight="bold">
                  {callLog.call.type}
                </Badge>
              </Box>
            </GridItem>
          </Grid>
        </Box>

        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <GridItem>
            <Box p={4} borderWidth="1px" borderRadius="md" height="100%">
              <Heading as="h3" size="md" mb={4} color="gray.700">
                Call Recording
              </Heading>
              <Flex alignItems="center" flexDirection="column">
                <Box width="100%">
                  <audio
                    ref={audioRef}
                    src={callLog.recordingUrl}
                    controls
                    style={{ width: "100%" }}
                  />
                </Box>
              </Flex>
            </Box>
          </GridItem>
          <GridItem>
            <Box p={4} borderWidth="1px" borderRadius="md" height="100%">
              <Heading as="h3" size="md" mb={4} color="gray.700">
                Call Summary
              </Heading>
              <Text fontSize="lg" fontStyle="italic" color="gray.600">
                {callLog.summary}
              </Text>
            </Box>
          </GridItem>
        </Grid>

        <Box bg={cardBg} borderRadius="md" borderWidth="1px" p={6} mt={3} height="calc(100vh - 490px)" overflowY="scroll" 
         css={{
          '&::-webkit-scrollbar': {
            width: '0px',
          },
          '&::-webkit-scrollbar-track': {
            width: '0px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'transparent',
          },
        }}
        >
          <Heading as="h3" size="md" mb={4} color="gray.700">
            Conversation
          </Heading>
          <VStack spacing={4} align="stretch">
            {messagesWithoutFirst.map((msg, index) => (
              msg.message && (
              <Flex
                key={index}
                justifyContent={msg.role === "bot" ? "flex-start" : "flex-end"}
              >
                <Box
                  bg={msg.role === "bot" ? "blue.100" : "green.100"}
                  p={4}
                  borderRadius="2xl"
                  maxWidth="80%"
                  position="relative"
                >
                  <Avatar
                    size="sm"
                    name={msg.role === "bot" ? "AI" : "User"}
                    bg={msg.role === "bot" ? "blue.400" : "green.400"}
                    position="absolute"
                    top="-10px"
                    color="white"
                    left={msg.role === "bot" ? "-10px" : "auto"}
                    right={msg.role === "bot" ? "auto" : "-10px"}
                  />
                  <Text mt={2}>{msg.message}</Text>
                </Box>
              </Flex>
              )
            ))}
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default CallDetail;
