import React from "react";
import { HStack, IconButton, Tooltip } from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  FaRegUserCircle,
  FaEdit,
  FaEye,
  FaEnvelopeOpenText,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Buttons = ({ workspaceId, agentId }) => {
  const navigate = useNavigate();

  const buttons = [
    {
      label: "Go Back",
      icon: <ChevronLeftIcon boxSize={8} />,
      onClick: () => navigate(`/dashboard/${workspaceId}/voice-agents/`),
    },
    {
      label: "Edit Agent",
      icon: <FaRegUserCircle boxSize={8} />,
      onClick: () => navigate(`/dashboard/${workspaceId}/voice-agent/agents/${agentId}/edit`),
    },
    {
        label: "Agent Details",
        icon: <FaEye boxSize={8} />,
        onClick: () => navigate(`/dashboard/${workspaceId}/voice-agent/agents/${agentId}`),
      },
    {
      label: "Edit Form",
      icon: <FaEdit boxSize={8} />,
      onClick: () => navigate(`/dashboard/${workspaceId}/voice-agent/agents/${agentId}/form-edit`),
    },
    {
      label: "View Submissions",
      icon: <FaEnvelopeOpenText boxSize={8} />,
      onClick: () => navigate(`/dashboard/${workspaceId}/voice-agent/agents/${agentId}/submissions`),
    },
  ];

  return (
    <HStack mb={2} top={4} left={4}>
      {buttons.map((button, index) => (
        <Tooltip key={index} label={button.label} placement="right" hasArrow>
          <IconButton
            icon={button.icon}
            variant="ghost"
            onClick={button.onClick}
            aria-label={button.label}
            bg="white"
            borderColor="gray.200"
            borderWidth="2px"
            color="gray.500"
            size="lg"
          />
        </Tooltip>
      ))}
    </HStack>
  );
};

export default Buttons;