import React from 'react';
import { Box, Icon } from '@chakra-ui/react';
import { Handle, Position } from 'reactflow';
import { FaPhone } from 'react-icons/fa';
import { useNodeContext } from '../NodeContext';

const TransferNode = ({ id, data }) => {
  const { selectedNode, setSelectedNode } = useNodeContext();

  const handleClick = () => {
    setSelectedNode({ id, type: 'transferNode', label: data.label });
  };

  return (
    <Box
      onClick={handleClick}
      style={{
        backgroundColor: 'white',
        padding: '16px',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        fontWeight: 'bold',
        color: '#222222',
        border: `2px solid ${selectedNode && selectedNode.id === id ? '#3182CE' : '#CBD5E0'}`,
        borderRadius: '10px',
        position: 'relative',
      }}
    >
      <Icon as={FaPhone} w={6} h={6} color="#54ebaf" />
      <span style={{ marginLeft: '8px' }}>Transfer</span>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: '#CBD5E0', width: 10, height: 10, borderRadius: '50%', left: '-6px' }}
      />
    </Box>
  );
};

export default TransferNode;
