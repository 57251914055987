import React, { useState, useEffect, useCallback } from 'react';
import { Stack, HStack, VStack, Text, Input, Button, Avatar, Box, Flex, useToast, Spinner } from '@chakra-ui/react';
import { FiSend } from 'react-icons/fi';
import { getFirestore, doc, onSnapshot, updateDoc, arrayUnion } from 'firebase/firestore';
import axios from 'axios';

const ContactMessages = ({ contactId, workspaceId }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [contact, setContact] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const toast = useToast();

  const fetchMessages = useCallback(() => {
    if (!contact) return;
    const db = getFirestore();
    const messageRef = doc(db, 'workspaces', workspaceId, 'messages', contact.phoneNumber);

    const unsubscribe = onSnapshot(messageRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        setMessages(data.messages || []);
      } else {
        console.log('No messages found for this contact');
        setMessages([]);
      }
    });

    return unsubscribe;
  }, [workspaceId, contact]);

  useEffect(() => {
    const fetchContact = async () => {
      const db = getFirestore();
      const contactRef = doc(db, 'workspaces', workspaceId, 'contacts', contactId);
      const unsubscribe = onSnapshot(contactRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          setContact(docSnapshot.data());
        } else {
          console.log('No such contact!');
        }
      });

      return unsubscribe;
    };

    const unsubscribeContact = fetchContact();

    return () => {
      if (unsubscribeContact) {
        unsubscribeContact.then(unsubscribe => unsubscribe());
      }
    };
  }, [workspaceId, contactId]);

  useEffect(() => {
    if (contact) {
      const unsubscribe = fetchMessages();
      return () => unsubscribe();
    }
  }, [fetchMessages, contact]);

  const handleSendMessage = async () => {
    if (newMessage.trim() && contact && !isSending) {
      setIsSending(true);
      const db = getFirestore();
      const messageRef = doc(db, 'workspaces', workspaceId, 'messages', contact.phoneNumber);

      // Find the first 'sending' message to get the correct 'from' and 'to' numbers
      const firstSentMessage = messages.find(msg => msg.type === 'sending');

      if (!firstSentMessage) {
        toast({
          title: "Error sending message",
          description: "No previous sent message found to determine the correct phone numbers.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setIsSending(false);
        return;
      }

      const newMessageObj = {
        body: newMessage,
        dateCreated: new Date().toISOString(),
        from: firstSentMessage.from,
        to: firstSentMessage.to,
        type: 'sending'
      };

      try {
        // Update Firestore first
        await updateDoc(messageRef, {
          messages: arrayUnion(newMessageObj)
        });

        // Send message via API
        const payload = {
          message: {
            phoneNumber: { number: newMessageObj.from },
            call: { customer: { number: newMessageObj.to } },
            toolCalls: [
              { function: { arguments: { message: newMessage } } }
            ]
          }
        };

        console.log('Sending payload:', payload);

        const response = await axios.post('https://api.clientbase.dev/message', payload);
        console.log('API Response:', response.data);

        toast({
          title: "Message sent",
          status: "success",
          duration: 2000,
          isClosable: true,
        });

        setNewMessage('');
      } catch (error) {
        console.error('Error details:', error.response ? error.response.data : error.message);
        toast({
          title: "Error sending message",
          description: error.response ? error.response.data.message : error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsSending(false);
      }
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  return (
    <Stack spacing={3} overflowY="auto" height="100%">
      <VStack 
        align="stretch" 
        spacing={4} 
        flex={1} 
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "gray.300",
            borderRadius: "24px",
          },
        }}
      >
        {messages.map((message, index) => (
          <Flex 
            key={index} 
            justifyContent={message.type === 'sending' ? 'flex-end' : 'flex-start'}
            width="100%"
          >
            {message.type === 'received' && <Avatar size="sm" marginRight={2} />}
            <Box 
              bg={message.type === 'sending' ? 'blue.100' : 'gray.100'}
              p={2} 
              borderRadius="md"
              maxW="70%"
            >
              <Text fontSize="sm">{message.body}</Text>
              <Text fontSize="xs" color="gray.500" textAlign="right">
                {formatDate(message.dateCreated)}
              </Text>
            </Box>
            {message.type === 'sending' && <Avatar size="sm" marginLeft={2} />}
          </Flex>
        ))}
      </VStack>
      
      <HStack>
        <Input 
          placeholder="Type a message..." 
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && !isSending && handleSendMessage()}
          isDisabled={isSending}
        />
        <Button 
          onClick={handleSendMessage} 
          colorScheme="blue" 
          isLoading={isSending}
          disabled={isSending}
        >
          {isSending ? <Spinner size="sm" /> : <FiSend />}
        </Button>
      </HStack>
    </Stack>
  );
};

export default ContactMessages;